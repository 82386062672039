// ref: https://smacss.com/files/smacss-ja.pdf
// SMACSSにおけるmoduleのstyleを記述します
// 1モジュール1ファイルでの作成を心がけましょう
// (1つのファイルの中でスタイルが完結していることが望ましいです)

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: var(--color-white);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
}

@media #{$sp} {
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
}
