// ref: https://smacss.com/files/smacss-ja.pdf
// SMACSSにおけるmoduleのstyleを記述します
// 1モジュール1ファイルでの作成を心がけましょう
// (1つのファイルの中でスタイルが完結していることが望ましいです)

//ツールチップ
.tooltip {
  position: relative;
  cursor: pointer;

  .icon-info1 {
    width: 16px;
    height: 16px;
  }

  &-description {
    width: 250px;
    position: absolute;
    top: 50px;
    left: -82px;
    padding: 5px;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    font-size: 12px;
    line-height: 1.4;
    transition: 0.5s all;
    border-radius: var(--border-radius);
    background: var(--color-white);
    box-shadow: var(--box-shadow);
    border: 1px solid var(--color-gray-pale);

    &:after {
      content: "";
      position: absolute;
      top: -15px;
      left: 50%;
      margin-left: -15px;
      border: 7px solid transparent;
      border-bottom: 10px solid var(--color-white);
      filter: drop-shadow(0 -1px 0 rgba(0, 0, 0, 0.2));
    }
  }

  &:hover .tooltip-description {
    visibility: visible;
    opacity: 1;
    z-index: 2;
  }
}
