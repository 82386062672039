// ref: https://smacss.com/files/smacss-ja.pdf
// SMACSSにおけるmoduleのstyleを記述します
// 1モジュール1ファイルでの作成を心がけましょう
// (1つのファイルの中でスタイルが完結していることが望ましいです)

// MEMO:: Gridに必要なため分離

//---------------------------

/* 星のレーティング*/

//---------------------------

// app/frontend/stylesheets/front/pages/top/index.scss の553行目から移植
//レビュー数
.review-count {
  display: flex;
  align-items: flex-end;
  line-height: 1;
  position: relative;
  font-size: 20px;
  font-weight: bold;

  &::before {
    // TODO: アイコン変更
    @include svgIcon("icon-comment-pink");
    width: 18px;
    height: 18px;
    margin:3px 10px 0 0;
  }
}

.star-rating-wrap {
  display: flex;
  align-items: center;
  &:has(.fa-solid) {
    flex-direction: column;
    align-items: center;
  }
  .product-head & {
    flex-direction: unset;
  }
  .star-score {
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-size: 20px;
    font-weight: bold;
    color: var(--color-primary);
    width: 100%;
    > div {
      font-size: 10px;
    }
    .star-width-16 & {
      font-size: 16px;
    }
    .star-width-12 & {
      font-size: 14px;
    }
  }
}
.product-card {
  .star-rating-wrap:has(.fa-solid) {
    flex-direction: row;
    align-items: center;
    @media #{$sp} {
      flex-direction: column;
    }
  }
}
.star-rating {
  display: flex;

  .star-rating-item {
    width: 117.25px;
    height: 20px;
    background-size: 117.25px 20px;
  }

  &.one-star-rating .star-rating-item {
    @include svgIcon("icon-star-rating-one");
  }

  &.zero .star-rating-item {
    @include svgIcon("star-0");
  }

  &.zero.half .star-rating-item {
    @include svgIcon("star-0-5");
  }
  &.one .star-rating-item {
    @include svgIcon("star-1");
  }
  &.one.half .star-rating-item {
    @include svgIcon("star-1-5");
  }
  &.two .star-rating-item {
    @include svgIcon("star-2");
  }
  &.two.half .star-rating-item {
    @include svgIcon("star-2-5");
  }
  &.three .star-rating-item {
    @include svgIcon("star-3");
  }
  &.three.half .star-rating-item {
    @include svgIcon("star-3-5");
  }
  &.four .star-rating-item {
    @include svgIcon("star-4");
  }
  &.four.half .star-rating-item {
    @include svgIcon("star-4-5");
  }
  &.five .star-rating-item {
    @include svgIcon("star-5");
  }
}
.star-width-16 {
  .star-rating-wrap span,
  .review-count {
    font-size: 16px;
    align-items: flex-start;
    &::before {
      width: 16px;
      height: 16px;
    }
  }
  .star-rating {
    .star-rating-item {
      width: calc(117.25px / 20 * 16);
      height: 16px;
      background-size: calc(117.25px / 20 * 16) 16px;
    }
  }
}

.star-width-12 {
  .review-count {
    font-size: 14px;
    align-items: flex-start;
    &::before {
      width: 12px;
      height: 12px;
    }
  }
  .star-rating {
    .star-rating-item {
      width: calc(117.25px / 20 * 12);
      height: 12px;
      background-size: calc(117.25px / 20 * 12) 12px;
    }
  }
}

.one-star-style {
  .star-width-12 {
    .product-rating-wrap {
      flex: 4;
      .star-rating {
        .star-rating-item {
          width: 24px;
        }
      }
    }
  }
  .h-216 {
    height: 216px;
  }
}
