@import '../../base';
@import '../../module/scrollbar';

@mixin tooltip {
  display: none;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  border-radius: var(--border-radius);
  z-index: 2;
}

//--------------------------------------------------------------------------------------------*/

/* HEADER */

//---------------------------

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: map-get($z-index, header);
  background: var(--color-white);

  &~.main-content {
    margin-top: var(--header-h);
  }

  &.simple-header {
    padding-top: 0;

    &~.main-content {
      margin-top: var(--header-h);

      &.m-t-0 {
        margin-top: 0;
      }
    }
  }

  &:has(.header-news) {
    padding-top: 0;
  }
}

// セキュア用ログインページヘッダー
.simple-header {
  border-bottom: 3px solid #bbc8db;
  .s-header-logo {
    display: flex;
    align-items: center;
    font-weight: bold;
    &:hover {
      opacity: 0.7;
    }
    a {
      aspect-ratio: 4.75 / 1;
      overflow: visible;
      position: relative;
      text-decoration: none;
      width: 400px;
      height: 40px;
      padding: 0;
      &:hover {
        background-color: transparent;
      }
      @media #{$sp} {
        width: 250px;
      }
      img {
        display: block;
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.header-news {
  @include gentle-flex();
  width: 100%;
  height: var(--header-news-h);
  padding: 1ch;
  position: relative;
  color: #fff;
  background: var(--color-gradient-primary);

  >p {
    font-size: 14px;
    font-weight: bold;
    display: block;
    text-align: center;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      display: inline-block;
      color: var(--color-black);
      padding: 0;

      &:hover {
        color: var(--color-black);
        background: transparent;
      }
    }
  }

  .news-remove-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

$header-news-h: var(--header-news-h);

.header-inner {
  height: var(--header-h);
  position: relative;
  width: 100%;
  background: var(--color-white);

  .site-header.has-news-0 & {
    top: 0;
  }

  @media #{$sp} {
    border-bottom: 1px solid var(--color-gray-pale);
    padding: 0;
  }

  .simple-header & {
    height: var(--header-h);
    padding: 0;
  }

  &-wrap {
    display: flex;
    align-items: center;
    gap: 24px;
    height: var(--header-h);
    position: relative;
    width: 100%;

    @media #{$sp} {
      justify-content: space-between;

      .sp-search {
        width: 20px;
        height: auto;
      }
    }

    .simple-header & {
      max-width: var(--main-content-narrow-width);
      height: var(--header-h);
      margin: 0 auto;

      .header-logo-img {
        width: 120px;
        height: auto;
      }
    }
  }
}

@for $i from 1 through 3 {
  .site-header.has-news-#{$i}~.main-content {
    top: calc(#{$header-news-h} * #{$i});
  }
}

.header-logo {
  a {
    aspect-ratio: 4.75 / 1;
    @include gentle-flex();
    overflow: visible;
    position: relative;
    text-decoration: none;
    width: 142px;
    height: 40px;
    padding: 0;

    @media #{$sp} {
      aspect-ratio: auto;
    }

    &:hover {
      background: transparent;
    }

    .header-logo-img {
      display: block;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      image-rendering: auto;

      @media #{$sp} {
        width: 80%;
        height: auto;
      }
    }
  }
}

.search-box {
  width: 100%;
  flex: 1 1;
  position: relative;

  &.opacity {
    opacity: 0;
  }
}

.g-nav {
  --gap-10-15: clamp(0.625rem, -0.316rem + 1.57vw, 0.938rem);
  gap: var(--gap-10-15);

  @media #{$sp-tablet} {
    gap: 0;
    padding-right: 15px;
    height: var(--header-h);

    &:last-child {
      padding-right: 0;
    }
  }

  .g-nav-list {
    --gap-5-20: clamp(0.313rem, -2.512rem + 4.7vw, 1.25rem);
    gap: var(--gap-5-20);
    --font-size12-14: clamp(0.75rem, 0.373rem + 0.63vw, 0.875rem);
    font-size: var(--font-size12-14);
    font-weight: 700;
    line-height: 1.3;

    @media only screen and (min-width: 701px) and (max-width: 1150px) {
      font-size: 10px;
    }

    a {
      color: var(--color-black);

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  .login {
    @include gentle-flex();
    width: 40px;
    height: 40px;
    color: var(--color-black);
    background: var(--color-white);
    border: 1px solid var(--color-gray-pale);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    cursor: pointer;

    &:before {
      position: absolute;
      @include svgIcon("icon-account");
      width: 24px;
      height: 24px;
    }
  }
}

.header-btn-wrap {
  @include gentle-flex();
  gap: 15px;
  position: relative;

  .header-btn {
    --size-w: 96px;
    height: 40px;
    --size-content: none;
    --m-t-btn-unit: 0;

  }

  .login,
  .icon-review a {
    &:hover {
      background: var(--color-primary-bright);
    }
  }

  a {
    @include gentle-flex();
    gap: var(--gutter);
    width: 100%;
    height: 100%;
    --font-size12-14: clamp(0.75rem, 0.373rem + 0.63vw, 0.875rem);
    font-size: var(--font-size12-14);
    padding: 0 !important;

  }

  @media #{$sp-tablet} {

    .login-sp a,
    .review-sp a {
      font-size: 0;
    }
  }
}

.user-menu {
  width: 220px;
  position: absolute;
  top: 45px;
  right: 55px;
  border: 1px solid var(--color-gray-pale);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background: var(--color-white);
  display: none;

  &.is-active {
    display: block;
  }

  a {
    justify-content: flex-start;

    &.mysheet,
    &.logout {
      margin-left: 40px;
    }
  }

  .icon-user,
  .icon-heart,
  .icon-eye {
    width: 24px;
  }
}

.speech-bubble-wrap {
  display: flex;
  align-items: center;
  // flex: 1 0 0;
  overflow: visible;
  position: relative;

  .speech-bubble {
    width: auto;
    max-width: 100%;
    border-radius: 30px;
    padding: 5px 10px;
    position: absolute;
    top: 25px;
    left: 30px;
    font-size: 14px;
    color: #fff;
    background: var(--color-primary);

    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 30px;
      transform: translateX(-50%);
      border: solid transparent;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent var(--color-primary) transparent;
    }
  }
}

//SP & タブレット menu
@media #{$sp-tablet} {
  .menu-toggle {
    display: block;
    cursor: pointer;
    position: relative;
    z-index: map-get($z-index, sp-menu-btn);
    width: 30px;
    height: 30px;

    .burger-line.top {
      transform: translateY(-3px) scaleX(0.88235);
    }

    .burger-line.bottom {
      transform: translateY(3px) scaleX(0.88235);
    }

    input:checked+label .burger-line .line.bottom {
      transform: rotateZ(45deg);
    }

    input:checked+label .burger-line .line.top {
      transform: rotateZ(-45deg);
    }

    input:checked+label .burger-line.bottom {
      transform: none;
    }

    input:checked+label .burger-line.top {
      transform: none;
    }

    input:checked+label .line.bottom {
      transform: none;
      transition-delay: 0.1s;
    }

    input:checked+label .line.top {
      transform: none;
      transition-delay: 0.1s;
    }

    .burger-line {
      transition: transform 0.2s ease-in-out 0.1s;
    }

    input:checked+label .burger-line {
      transition-delay: 0s;
    }

    .line {
      transition: transform 0.2s ease-in-out;
    }

    button {
      all: unset;
      cursor: pointer;
      display: block;
    }

    button * {
      pointer-events: none;
    }

    .burger {
      height: 30px;
      width: 30px;
      display: block;
      position: relative;

      &.active {
        position: fixed;
        top: 10px;
        right: 10px;
      }
    }

    .burger-line {
      position: absolute;
      left: 0;
      top: 15px;
    }

    .line {
      height: 2px;
      border-radius: 3px;
      background: var(--color-primary);
      width: 30px;
    }

    input {
      display: none;
    }
  }

  .menu-toggle.active+.menu {
    opacity: 1;
  }
}

.menu-sp {
  width: 60%;
  height: 100vh;
  transition: all 0.2s;
  transform: translate(100vw);
  position: fixed;
  top: 0;
  right: 0;
  z-index: map-get($z-index, sp-menu);
  padding: 50px 10px 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(180%) blur(20px);

  &.open {
    transform: translate(0);
  }

  li {
    padding: 10px;

    a {
      color: var(--color-white);
      font-weight: bold;
      text-decoration: none;
      font-size: 14px;
      padding: 0;
    }
  }
}

.sp-header-search-form {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  z-index: map-get($z-index, header);
  top: 40px;
  width: 100%;
  left: 0;
  background: var(--color-white);
  filter: drop-shadow(0 7px 8px rgba(240, 243, 245, 1));

  &.open {
    display: block;
    opacity: 1;
  }
}

/*---------------------------全ページで読み込む---------------------------*/

/* パーツ */

//---------------------------

//検索box
.search-form {
  display: flex;
  height: var(--serch-form-h, 48px);
  background: var(--color-primary-bright);
  border-radius: 25px;
  overflow: hidden;
  position: relative;

  .header-inner-wrap & {
    max-width: 500px;
    min-width: 270px;
  }

  .product-review & {
    border-radius: var(--border-radius);
  }

  .be-in-search & {
    height: var(--serch-form-h, 44px);
    border-radius: var(--border-radius);
  }

  &-input {
    position: relative;
    box-sizing: border-box;
    width: calc(100% - 35px);
    height: 100%;
    padding: 0 0 0 15px;
    border: none;
    outline: none;
    font-size: 16px;
    overflow: hidden;
  }

  &::before {
    @include svgIcon("icon-search");
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .search-form-btn {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
  }

  &.without-icon {
    &::before {
      background: none;
    }
  }
}

//検索サンプル
.search-word-wrap {
  display: none;
  background: var(--color-white);
  width: 100%;

  .header-search & {
    max-width: 700px;
    position: absolute;
    left: 0;
    padding-top: 30px;
    border-top: none;
  }

  .search-word-tag {
    li span {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      text-align: center;
      border-radius: 50px;
      cursor: pointer;
    }
  }
}

//レビュー数
.review-count {
  display: flex;
  align-items: flex-end;
  line-height: 1;
  position: relative;
  font-size: 20px;
  font-weight: bold;
  width: 100%;

  &::before {
    // TODO: アイコン変更
    @include svgIcon("icon-comment-pink");
    width: 18px;
    height: 18px;
    margin-right: 10px;
    @media #{$sp} {
      margin-right: 5px;
    }
  }
}

//動画
.movie {
  width: var(--movie-w, 75%);
  height: fit-content;

  &-wrap {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
      margin: 0 auto;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

//パネル
.panel-wrap {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 900px;
  height: 100px;
  z-index: map-get($z-index, modal);
  border-radius: var(--border-radius);

  &.sp-block {
    @media #{$sp} {
      padding: 25px 20px 10px;
      border-radius: 0;
      bottom: 0;
      height: 150px;
      flex-direction: column;
      justify-content: end;

      .panel-closer-button {
        right: -10px;
      }

      ul {
        margin-bottom: 10px;

      }
    }
  }

  &.active {
    display: flex;
  }

  .grid-list--4 {
    display: flex;

    li {
      position: relative;
      min-width: 60px;
      height: 46px;
      flex: 1;
      background: transparent;
      border: 1px dotted var(--color-white);

      // &:before {
      //   position: absolute;
      //   top: -20px;
      //   left: 0;
      //   content: counter(category-product-comparison) ". ";
      //   counter-increment: category-product-comparison;
      //   font-size: 12px;
      //   color: var(--color-white);
      // }
      &.active {
        background: var(--color-white);
      }
    }
  }

  .panel-closer-button {
    position: absolute;
    top: -25px;
    right: -35px;
    @include svgIcon("icon-cross-circle");
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .remove-button {
    position: absolute;
    top: -20px;
    right: 0;
    width: 36px;
    height: 36px;
    margin-right: -20px;
  }
}

//サイドナビゲーション
.nav-list {
  li {
    a:before {
      margin-right: 10px;
      @include arrow(left, 5px, var(--color-blue));
    }
  }
}

// MEMO:: Clientで必要なため分離
@import '../../module/tooltip';

//パンクず
.breadcrumb {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  max-width: var(--main-content-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  flex-wrap: wrap;

  &.no-icon {
    &:before {
      display: none;
    }
  }

  li {
    display: flex;
    align-items: center;

    a {
      padding: 0;
      width: max-content;

      &:hover {
        color: var(--color-primary);
        background: transparent;
      }
    }

    &:after {
      content: "";
      @include arrow(right, 1px, var(--color-black));
      border-width: 0 1px 1px 0;
      margin: 0 10px;
    }

    &.active {
      width: max-content;

      &:after {
        display: none;
      }

      color: var(--color-primary);
    }
  }
}

.breadcrumb .ellipsis {
  display: none;
  cursor: pointer;
}

.breadcrumb .hidden-li {
  display: flex;
}

@media #{$sp} {
  .breadcrumb .hidden-li {
    display: none;
  }

  .breadcrumb .ellipsis {
    display: flex;
    align-items: center;
    margin: 0 5px;
  }

  .breadcrumb.show-all .hidden-li {
    display: flex;
  }

  .breadcrumb.show-all .ellipsis {
    display: none;
  }
}

//ITreviewに参加しよう！の３つのリンク
.footer-content {
  li>img {
    @media #{$tablet} {
      width: 150px;
    }

    @media #{$sp} {
      width: 100px;
    }
  }

}

//一番下に固定のバナー
.fix-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 900px;
  height: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: map-get($z-index, fix-fotter);

  @media #{$sp} {
    height: auto;
  }

  @media #{$sp} {
    .sp-block {
      flex: none;

      a {
        flex: none;
        width: 150px;
      }
    }
  }

  &.closed {
    display: none;
  }

  >.fix-footer-close {
    position: absolute;
    top: -20px;
    right: -10px;
    cursor: pointer;

    @media #{$sp} {
      top: -25px;
      right: 0;
    }
  }
}

//画像あぷろーど
.upload-box {
  width: 100%;
  height: 100%;
  min-height: 180px;
  @include gentle-flex;
  flex-direction: column;
  gap: var(--gutter-s);

  color: var(--color-gray);
}

///*------------------------------------------------------*/

/*ページネーション*/

//---------------------------

.pagination {
  display: flex;
  padding: 2px;
  margin: 16px auto;
  width: fit-content;

  @media #{$sp} {
    width: 100%;
    max-width: var(--side-width);
  }
}

.pagination li {
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-weight: bold;

  a {
    @include gentle-flex();
    width: 100%;
    height: 100%;
    padding: 0;
    position: relative;
    z-index: 1;
  }

  &:not(.arrow) {
    padding: 2px;
    background: transparent;

    a:hover {
      background-color: var(--color-primary);
      color: var(--color-white);
      border-radius: 8px;
    }
  }

  &.first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &.current span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    color: var(--color-white);
    border-radius: 8px;
  }

  &.ellipsis {
    &:after {
      content: "...";
      display: inline-block;
    }
  }

  &.arrow {
    a {
      background: initial;
    }

    &:after,
    &:before {
      position: absolute;
      top: 35%;
      left: 50%;
    }

    &.arrow-left:before {
      @include arrow(left, 5px, var(--color-gray), 3px);
    }

    &.arrow-right:after {
      @include arrow(right, 5px, var(--color-gray), 3px);
    }
  }
}

//--------------------------------------------------------------------------------------------*/

/* FOOTER */

//---------------------------

//課題から探す
.task-search {
  @media #{$sp} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      width: 100px;

      span {
        font-size: 12px;
      }
    }
  }
}

.footer {
  margin-top: 150px;
  padding-bottom: 30px;
  border-top: 3px solid transparent;
  border-image: var(--color-gradient-primary);
  border-image-slice: 1;

  @media #{$sp} {
    margin-top: 80px;
  }

  &-inner {
    width: 100%;
    max-width: var(--main-content-width);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--gutter);
    padding-right: var(--gutter);

    .logo-itreview {
      @media #{$sp} {
        width: 100px;
      }
    }

    dl {
      dd a {
        color: var(--color-black);
        font-size: 14px;

        @media #{$sp} {
          font-size: 12px;
        }
      }
    }

    @media #{$sp-tablet} {
      .footer-inner-block {
        flex-direction: column;
        font-size: 14px;

        >div {
          width: 100%
        }

        ;
      }

      @media #{$sp-tablet} {
        .sp-block {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
          margin-top: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid var(--color-gray-pale);

          &:last-child {
            border-top: 30px;
          }
        }

        dt {
          grid-column: span 2;
        }

        dd:nth-child(odd) {
          @media #{$sp} {
            margin-right: 5px;
          }
        }
      }

    }
  }
}

.simple-footer {
  border-top: none;
  text-align: center;
  font-size: 12px;
  padding: 30px;

  @media #{$sp} {
    padding: 15px;
  }
}

/*---------------------------パーツー---------------------------*/

//タブ
.width-max {
  margin-top: 40px;
  margin-left: calc(50% - 49vw);
  margin-right: calc(50% - 49vw);
  border-bottom: 1px solid var(--color-gray-bright);
  background: var(--color-white);

  z-index: map-get($z-index, tab);
}

.tab-wrap {
  display: flex;
  align-items: baseline;
  gap: 2rem;
  max-width: var(--main-content-width);
  margin: 0 auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-bottom: 10px;
  color: var(--color-black);
  overflow-x: auto;
  overflow-y: visible;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 24px;


  >.tab {
    scroll-snap-align: start;

    >a {
      padding: 0;

      &:hover {
        color: var(--color-primary);
        font-weight: bold;
        background: transparent;
      }
    }

    &.active {
      font-weight: bold;
      color: var(--color-primary);
      background: transparent !important;

      &:after {
        content: "";
        position: relative;
        bottom: -10px;
        display: block;
        width: 100%;
        height: 4px;
        background: var(--color-primary);
      }
    }
  }
}

#category-sidebar-knowledge>.sticky {
  max-height: 80vh;
  overflow: scroll;
}

@media #{$pc} {

  .category-tab-link:hover+.submenu,
  .submenu:hover {
    display: flex;
  }

  .submenu li a:hover {
    border-bottom: 4px solid white;
    background: none;
    border-radius: 0;
  }
}

.submenu {
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: var(--color-primary);
  color: white;
  border-radius: 4px;
}

.submenu:after {
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: -15px;
  margin-left: 10px;
  border: solid transparent;
  border-color: transparent;
  border-bottom-color: var(--color-primary);
  border-width: 10px;
  pointer-events: none;
  content: "";
}

/* スマホアコーディオン用 */
@media #{$sp-tablet} {
  .tab-wrap {
    position: static;

  .submenu {
    height: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90vw;
    box-shadow: none;
    transition: height 0.3s ease-out, padding 0.3s ease-out;
    position: absolute;
    z-index: map-get($z-index, sp-menu);

    a:hover {
      color: var(--color-primary);
    }

    &.open {
      padding: 10px;
    }
  }
}
  .category-tab-link {
    cursor: pointer;

    &.active {
      color: var(--color-primary) !important;
    }
  }
}


//---------------------------

/* サークル*/

//---------------------------
.minimal-donut {
  border-radius: 50%;
  color: var(--color-primary);
  @include gentle-flex;
  position: relative;
  width: 50px;
  height: 50px;
}

.minimal-donut__hole {
  border-radius: 50%;
  height: 80%;
  width: 80%;
  background: var(--color-white);
}

.minimal-text {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

// MEMO:: Gridに必要なため分離
@import '../../module/star_rating';

//---------------------------

/* アコーディオン*/

//---------------------------
.accordion-header {
  position: relative;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:after {
    content: "+";
    font-size: 1.5em;
    position: absolute;
    right: 1.1em;
    top: 0;
    bottom: 0;
    transition: transform 0.2s cubic-bezier(0.2, 0.6, 0.3, 1.1);
    display: flex;
    align-items: center;
  }

  .ranking-list-wrap & {
    .box {
      @media #{$sp-tablet} {
        margin-right: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 40px;
        margin-right: 10px;

        .rank-num {
          flex-direction: column;
          align-items: center;
        }
      }

      @media #{$sp} {
        max-width: 30px;

      }
    }

    &:after {
      right: 15px;
    }
  }

  &.active {
    border-bottom: 1px solid var(--color-gray-pale);

    &:after {
      content: "-";
      right: 29px;
      transform: rotate(360deg);
    }

    .ranking-list-wrap & {
      &:after {
        right: 20px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-image: var(--color-gradient-primary);
    }

    .sidebar & {
      &:before {
        display: none;
      }
    }
  }
}

.accordion-content {
  display: none;
  transition: max-height 0.3s ease;

  .accordion-header.active+& {
    display: block;
    height: auto;
  }
}

/*---------------------------*/

/*ログインー会員登録ボタン */

/*---------------------------*/
.speech-bubble-left {
  position: relative;
  border-radius: 10px;

  &:before {
    @include svgIcon("speech-bubble-left-gray");
    width: 12px;
    height: 18px;
    position: absolute;
    left: -12px;
  }
}

.speech-bubble-right {
  position: relative;
  border-radius: 10px;
  width: fit-content;

  &:before {
    @include svgIcon("speech-bubble-right-pink");
    width: 12px;
    height: 18px;
    position: absolute;
    right: -12px;
  }
}

#regist_button,
#deactivate_btn {
  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
}

#deactivate_btn.btn-black:disabled:hover {
  background: var(--color-black);
}

.btn-gradient:has(#review_submit_button.disabled) {
  background: var(--color-gray);
  cursor: not-allowed;
}

#review_submit_button {
  &.disabled {
    color: var(--color-white) !important;
    cursor: not-allowed;
    pointer-events: none;
  }
}

#lead_post_btn.disabled {
  background: var(--color-gray);
  color: var(--color-white) !important;
  cursor: not-allowed;
  pointer-events: none;
}

.agreement-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .link-txt {
    margin-top: 10px;
  }
}

/*---------------------------*/

/*ログインー会員登録ボタン */

/*---------------------------*/

.register-btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-btn-list {
  width: 100%;
  display: flex;
  flex-direction: column;

  li {
    width: 100%;
    --size-content: 100%;
    height: 48px;
    position: relative;
    padding: 0;
    background: var(--color-white);
    font-size: 16px;
    margin-top: 20px;

    @media #{$sp} {
      width: 300px;
    }

    .icon-register {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
    }
  }
}

//--------------------------------------------------------------------------------------------*/

/* TOP */

//---------------------------
.main-area {
  position: relative;

  @media #{$sp} {
    margin-top: var(--header-h);
  }

  .picture-wrap {
    width: 100%;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    scroll-snap-type: x mandatory;

    .slide-inner {
      position: relative;
      white-space: nowrap;
      transition: 0.5s left ease-in-out;
      width: 100%;
      margin: 0 auto;

      /* iPad Proの横向き（ランドスケープ） */
      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
        height: 550px;
      }

      /* iPad Proの縦向き（ポートレート） */
      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
        height: 550px;
      }

    }

    .picture-box {
      display: inline-block;
      width: 100%;
      scroll-snap-align: start;

      +.picture-box {
        margin-left: -4px;
      }
    }
  }

  .dots {
    position: absolute;
    bottom: 200px;
    right: 50%;
    transform: translate(50%, -50%);
    display: flex;
    justify-content: center;
    z-index: 2;

    @media #{$sp} {
      width: 90%;
      max-width: 90%;
      position: unset;
      transform: none;
      margin: 0 auto 10px;
    }

    .dot {
      all: unset;
      margin-right: 5px;
      width: 26px;
      height: 10px;
      border-radius: 10px;
      background: var(--color-gray);
      margin-top: 10px;

      // TODO:: 問題ないか確認
      &.active {
        background: var(--color-primary);
        outline: 1px solid var(--color-primary);
      }
    }
  }

  .picture-box {
    position: relative;
    min-height: 580px;
    max-height: 580px;
    margin: 0 auto;

    @media #{$sp} {
      min-height: 500px;
      max-height: 500px;
    }

  }

  .top-slider-wrap {
    position: absolute;
    width: 100%;
    height: 100%;

    .top-slider-inner {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      position: absolute;
      top: 38%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: initial;
      z-index: 1;

      @media #{$tablet} {
        max-width: 500px;
        top: 30%;
      }

      @media #{$sp} {
        top: calc(50% + 48px);
        width: 90%;
        max-width: 90%;

      }

      /* iPad Proの横向き（ランドスケープ） */
      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
        max-width: 800px;
        top: 30%;
      }

      /* iPad Proの縦向き（ポートレート） */
      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
        max-width: 600px;
        top: 30%;
      }

      &.row-reverse {
        flex-direction: row-reverse;
      }
    }

    &.main-slider {
      h2 {
        font-size: 62px;

        @media #{$sp} {
          font-size: 30px;
        }
      }

      @media #{$sp} {
        .top-slider-inner {
          top: 0;
          width: 100%;
          max-width: 100%;
          transform: translate(-50%, -20px);
        }
      }

      .mainslider-image {
        margin: 0 auto;

        @media #{$sp} {
          display: none;
        }
      }

      .mainslider-image-sp {
        display: none;

        @media #{$sp} {
          display: block;

        }
      }
    }

    &.top-main-bg-image {
      max-height: 420px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
    }

    &.top-main-grade {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &:after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 47%,
            rgba(255, 255, 255, 1) 100%);

        @media #{$sp} {
          display: none;
        }
      }
    }

    &.grade-01,
    &.gradation1 {
      background: var(--color-gradient-third);
    }

    &.grade-02,
    &.gradation2 {
      background: var(--color-gradient-fourth);
    }

    &.grade-03,
    &.gradation3 {
      background: var(--color-gradient-fifth);
    }



    .top-slider-txt {
      h1 {
        letter-spacing: 4px;
        gap: 0;

        @media #{$pc} {
          --font-size30-60: clamp(1.875rem, -1.943rem + 6.37vw, 3.75rem);
          font-size: var(--font-size30-60);
        }

        @media only screen and (min-width: 1180px) and (max-width: 1400px) {
          --font-size40-50: clamp(2.5rem, 1.227rem + 2.12vw, 3.125rem);
          font-size: var(--font-size40-50);
        }

        @media #{$tablet} {
          --font-size32-40: clamp(2rem, 1.556rem + 1.48vw, 2.5rem);
          font-size: var(--font-size32-40);
        }


        @media #{$sp} {
          font-size: 28px;
          line-height: 1.2;
          max-width: 95%;
          margin: 0 auto;
        }

        >div {
          line-height: 1.1;
        }
      }

      p {
        letter-spacing: 2px;
        margin-top: 10px;

        margin-top: 0;
      }
    }

    .top-slider-picture {
      width: 100%;
      max-width: 400px;
      height: auto;
      max-height: 300px;

      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        max-height: 300px;
        object-fit: contain;
      }
    }
  }

  .mainslider-image {
    opacity: 0;
    animation: fadeIn 3s ease-out forwards;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  .search-box {
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 430px;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;

    @media #{$sp} {
      width: 90%;
      max-width: 90%;
      height: 48px;
      position: unset;
      transform: none;
      margin: 0 auto 10px;
    }

    .search-form {
      z-index: 2;
      width: 100%;
      max-width: 100%;

      @media #{$sp} {
        width: 90%;
        margin: 0 auto;
      }
    }
  }

  .search-word-wrap {
    position: absolute;
    top: 35px;
    left: 0;
    padding-top: 30px;
  }
}

.review-author-wrap {
  @include gentle-flex(center, flex-start);
  gap: var(--gutter-s);
}

.ranking-list {
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 10px;
  justify-content: space-around;

  /* iPad Proの横向き（ランドスケープ） */
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
    grid-template-columns: auto;
  }

  @media only screen and (min-width: 960px) and (max-width: 1010px) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

  }

  @media #{$pc} {
    grid-auto-flow: column;
  }

  @media #{$sp-tablet} {
    justify-content: normal;
    grid-auto-flow: row;

  }

  @media #{$sp} {
    gap: 0;
    grid-template-columns: auto;
  }

  li {
    .top-weekly-ranking {
      width: 100%;
      min-width: 260px;
      max-width: 260px;

      @media only screen and (min-width: 960px) and (max-width: 1050px) {
        min-width: 220px;
        max-width: 220px;
      }

      @media only screen and (min-width: 768px) and (max-width: 959px) {
        min-width: 400px;
        max-width: 400px;
      }

      @media #{$sp} {
        min-width: 100%;
        max-width: 100%;
      }
    }

    .top-weekly-star {
      @media only screen and (min-width: 960px) and (max-width: 1200px) {
        flex-direction: column;
        justify-content: center;
        gap: 0;
      }
    }

    .star-rating-wrap {
      @media only screen and (min-width: 1205px) {
        min-width: fit-content;
      }

      @media #{$sp-tablet} {
        min-width: fit-content;
      }
    }

    .review-count {
      @media #{$sp-tablet} {
        min-width: fit-content;
      }
    }

    @media #{$sp} {
      width: 100%;
      max-width: 100%;
      border: 1px solid var(--color-gray-pale);
      box-shadow: var(--box-shadow);
      border-radius: var(--border-radius);
      margin-bottom: 10px;
      padding: var(--gutter-ss);

      &:last-child {
        margin-bottom: 0;
      }

      .star-rating-wrap span {
        font-size: 14px;
        width: auto;
      }


      .review-count {
        font-size: 14px;

        @media only screen and (min-width: 1165px) and (max-width: 1385px) {
          min-width: fit-content;
        }
      }
    }

    .rank-num {
      width: 100%;
      min-width: 50px;
      max-width: 50px;
    }

    .product-ttl {
      flex: 1 1;
      width: 100%;

      @media #{$sp-tablet} {
        width: auto;
        max-width: 100%;
      }

      p:not(.small) {
        line-height: 1.1;
      }
    }
  }
}

.ranking-supplement {
  margin: 10px 20px;
}

//レビューカード
.article-card {
  word-break: break-all;
  width: 100%;
  min-width: 100%;

  a {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    flex-grow: 1;

    +a.flow {
      padding-bottom: var(--gutter-s);
      margin-top: var(--gutter-s);
    }
  }

  &--head {
    height: 100%;
    min-height: 70px;
    max-height: 70px;
    flex-direction: unset !important;

    .review-product-wrap {
      @include gentle-flex(center, flex-start);
      gap: var(--gutter-s);
    }
  }

  .review-author-wrap {
    flex: 1 1 auto;
    flex-grow: 1;
  }

  hr {
    height: 1px;
    background-color: var(--color-gray-pale);
  }
}

.blog-card {
  .blog-card-img {
    max-height: 170px;
  }

  .blog-card-list {
    @media #{$pc} {
      display: grid;
      grid-template: repeat(2, 1fr) / 500px repeat(3, 1fr);
      gap: var(--gutter);
    }

    @media only screen and (min-width: 961px) and (max-width: 1150px) {
      grid-template: repeat(2, 1fr) / 300px repeat(3, 1fr);
    }

    @media only screen and (min-width: 800px) and (max-width: 960px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--gutter);
      font-size: 14px;
    }

    @media #{$tablet} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--gutter);
    }

    @media #{$sp} {
      @include gentle-flex;
      gap: var(--gutter);
      flex-wrap: wrap;
    }

    li {
      &:first-child .blog-card-img {
        max-height: 280px;

        @media #{$sp} {
          width: 50%;
          max-width: 48%;
        }
      }

      &:nth-child(n + 2) {
        max-width: 236px;

        @media #{$sp-tablet} {
          max-width: 100%;
        }
      }

      &:nth-child(n + 2) .blog-card-img {
        max-height: 130px;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        &.top-useful-category-card {
          .label-wrap {
            line-height: 1;
          }
        }
      }

      @media #{$pc} {
        &:first-of-type {
          grid-row: 1 / span 2;
          grid-column: 1;

          a {
            flex-direction: column;
          }
        }

        &:nth-of-type(2) {
          grid-row: 1;
          grid-column: 2;
        }

        &:nth-of-type(3) {
          grid-row: 1;
          grid-column: 3;
        }

        &:nth-of-type(4) {
          grid-row: 1;
          grid-column: 4;
        }

        &:nth-of-type(5) {
          grid-row: 2;
          grid-column: 2;
        }

        &:nth-of-type(6) {
          grid-row: 2;
          grid-column: 3;
        }

        &:nth-of-type(7) {
          grid-row: 2;
          grid-column: 4;
        }
      }

      @media #{$tablet} {
        &:first-of-type {
          grid-column: 1 / -1;

          a {
            flex-direction: column;
          }
        }
      }

      @media #{$sp} {
        &:not(:first-child) a {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .block {
          flex: 1;
        }
      }
    }
  }
}

//--------------------------------------------------------------------------------------------*/

/* カテゴリー */

//---------------------------
.width-max {
  margin-top: 40px;
  margin-left: calc(50% - 49vw);
  margin-right: calc(50% - 49vw);

  background: var(--color-white);

  z-index: map-get($z-index, tab);

  .tab-container & {
    padding-top: 10px;
    border-bottom: 1px solid var(--color-gray-bright);
  }
}

/*カテゴリーを探す（TOP)*/
.category-nav {
  width: 100%;
  max-width: 300px;

  @media #{$sp} {
    max-width: 100%;
  }
}

.category-tab-content {
  width: 100%;

  .grid-list--3 {
    gap: var(--gutter-s);

    @media screen and (min-width: 768px) and (max-width: 959px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$tablet} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.top-search-category {
  li {
    @media #{$sp} {
      a {
        display: flex;
        align-items: center;
        min-height: 48px;
        padding-left: 30px;
      }
    }

    .back-button {
      padding-left: 30px;
    }

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-radius: 0 0 5px 5px;
    }

    &.bg-black a {
      cursor: default;
    }

    .arrow-l:after {
      @include arrow(left, 3px, var(--color-black));
      position: absolute;
      top: 46%;
      left: 10px;
    }

    .arrow-r:after {
      @include arrow(right, 3px, var(--color-black));
      position: absolute;
      top: 46%;
      right: 10px;
    }

    &:first-child a:hover {
      border-radius: 5px 5px 0 0;
    }

    &:last-child a:hover {
      border-radius: 0 0 5px 5px;
    }

    a:hover {
      color: var(--color-black);
      border-radius: 0;
    }

    &.bg-black a {
      pointer-events: none;
      cursor: default;

      &:hover {
        color: var(--color-white);
        background-color: var(--color-black);
      }
    }
  }
}

/*カテゴリーから探す*/
.category-filter-wrap {
  align-items: baseline;
  gap: 15px;
  max-width: var(--main-content-width);
  margin: 0 auto;
  padding: 0 var(--gutter) 10px;
  color: var(--color-gray);
}

.categories-table {
  border: 1px solid var(--color-gray-pale);
  height: 100%;
  margin: 0;
  table-layout: fixed;

  &+.categories-table {
    border-top: none;
  }

  .category & {
    tr a::after {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      @include arrow(right, 3px, var(--color-gray));
    }

    .categories-name {
      @media #{$sp} {
        width: 90%;
      }
    }
  }

  td {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    border-bottom: 1px solid var(--color-gray-bright);

    a {
      height: 100%;
      border-radius: 0;

      &:has(.categories-item) {
        background: var(--color-primary-bright);
      }
    }

    .categories-item {
      width: 90%;

      @media #{$sp} {
        font-size: 14px;
      }
    }
  }

  thead {
    tr:first-child {
      height: 64px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-image: var(--color-gradient-primary);
        z-index: 2;
      }
    }

    &.accordion-header {
      &:after {
        top: 10px;
        right: 15px;
      }

      &.active {
        border-bottom: 0;
      }

      tr:first-child {
        &:after {
          display: none;
        }
      }

      td {
        border-bottom: 0;

        &:after {
          display: none;
        }
      }
    }
  }

  tbody {
    tr {
      display: block;
      width: 100%;
      height: 45px;
      border-bottom: 1px solid var(--color-gray-pale);
    }

    &.accordion-content {
      tr:last-child {
        border-bottom: none;
      }

      td {
        border-bottom: none;

        &:after {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          @include arrow(right, 3px, var(--color-gray));
        }
      }
    }

    .categories-name {
      align-items: center;
      display: flex;

      .category & {
        display: block;
      }
    }
  }
}



.side-nav {
  dt.link {
    &:before {
      content: "";
      @include arrow(left, 4px, var(--color-blue));
      height: 4px;
      margin-right: 5px;
    }
  }
}

.first-sentence {
  position: relative;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 120px;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), var(--color-white));
  }

  &:has(~.js-close) {
    &:after {
      display: none;
    }
  }
}

.list-category {
  &-item {
    .hidden-checkbox {
      display: none;
    }

    .category-label {
      @include gentle-flex(center, space-between);
      padding: 10px 20px;
      cursor: pointer;
      position: relative;
    }

    .category-label {
      display: block;
      transition: background-color 0.3s;
    }

    .hidden-checkbox:checked+.category-label {
      background: var(--color-primary-bright);
      border-radius: var(--border-radius);
    }
  }
}

//ツールを選択　カテゴリーから探す
.list-category,
.sub-category,
.child-category {
  .list-category-item {
    font-size: 14px;
    position: relative;

    &:after {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      @include arrow(right, 3px, var(--color-gray));
    }
  }
}

//---------------------------------
//カテゴリーマークダウン //ToDo:未整理

.category-markdown table,
.ringi-markdown table {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 30px auto;
  width: calc(100% - 45px);
  border: 1px solid var(--color-gray-pale);
}

.category-markdown tfoot td,
.category-markdown tfoot th,
.category-markdown thead td,
thead th,
.ringi-markdown tfoot td,
.ringi-markdown tfoot th,
.ringi-markdown thead td,
thead th {
  padding: 5px;
  font-weight: 700;
  text-align: left;
}

.category-markdown table thead,
.ringi-markdown table thead {
  background-color: var(--color-primary);
  color: #fff;
}

.category-markdown tbody td,
.category-markdown tbody th,
.ringi-markdown tbody td,
.ringi-markdown tbody th {
  padding: 5px;
}

.category-markdown tbody tr:nth-child(2n),
.ringi-markdown tbody tr:nth-child(2n) {
  border-bottom: 0;
  background-color: var(--color-gray-pale);
}

.category-markdown table thead tr th:first-child,
.ringi-markdown table thead tr th:first-child {
  width: 30%;
}

.category-markdown table thead img,
.ringi-markdown table thead img {
  display: none;
}

.category-markdown table tbody,
.category-markdown table thead,
.ringi-markdown table tbody,
.ringi-markdown table thead {
  border-left: none;
  border-right: none;
}

.category-markdown table tbody td span,
.category-markdown table thead td span,
.ringi-markdown table tbody td span,
.ringi-markdown table thead td span {
  display: none;
}

@media #{$sp} {

  .category-markdown table,
  .ringi-markdown table {
    font-size: 14px;
    margin-left: 0;
    width: 100%;
  }

  .category-markdown table thead,
  .ringi-markdown table thead {
    display: none;
  }

  .category-markdown table tbody td,
  .category-markdown table tbody th,
  .ringi-markdown table tbody td,
  .ringi-markdown table tbody th {
    display: block;
    width: 100%;
  }

  .category-markdown table tbody tr,
  .ringi-markdown table tbody tr {
    display: block;
    padding: 15px 10px;
  }

  .category-markdown table tbody td,
  .ringi-markdown table tbody td {
    padding: 0;
  }

  .category-markdown table tbody td span,
  .ringi-markdown table tbody td span {
    display: block;
    margin: 5px 0;
    color: var(--color-primary);
  }
}

.category-markdown ul>li,
.ringi-markdown ul>li {
  list-style: disc;
  list-style-position: inside;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 5px;
}

.category-markdown img,
.ringi-markdown img {
  text-align: center;
  margin: 30px auto;
  display: block;
}


//Grid・カテゴリー・レビュー絞り込み
.filter-list {
  cursor: pointer;

  @media #{$sp} {
    width: 100%;
  }

  li {
    .active {
      cursor: default;
      color: var(--color-primary);
    }

    &:hover {
      color: var(--color-primary);
    }
  }
}

//---------------------------

/*チェックボックス、ラジオボックス */

//---------------------------
.input-box {
  position: relative;
  width: 24px;
  height: 24px;

  //マージンはerbで指定
  .products-narrow-down & {
    width: 100%;
    height: 100%;
    display: inline-grid;
  }
}

[type="checkbox"]+label[for],
[type="radio"]+label[for] {
  cursor: pointer;
}

.checkbox-button,
.radio-button {
  position: relative;
  width: 24px;
  height: 24px;

  .products-narrow-down & {
    width: 100%;
    height: 100%;
  }
}

.checkbox-button+label,
.radio-button+label {
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0;

  .products-narrow-down & {
    width: 100%;
    height: 100%;
    @include gentle-flex;
    position: inherit;
    padding: 0.5rem 0;
    gap: var(--gutter-ss);

    &:hover::before {
      background: var(--color-primary-bright);
    }
  }

  .filter-word-wrap & {
    height: 15px;
    width: 15px;
  }
}

.checkbox-button+label::before,
.radio-button+label::before {
  content: "";
  display: block;
  height: 24px;
  width: 24px;
  border: 1px solid var(--color-gray-dark);
  left: 0.75rem;
  top: 50%;
  background: var(--color-white);

  .products-narrow-down & {
    height: 20px;
    width: 20px;
  }

  .filter-word-wrap & {
    height: 15px;
    width: 15px;
  }
}

.checkbox-button+label::after,
.radio-button+label::after {
  @include svgIcon("icon-checkbox-check");
  background-color: var(--color-primary);
  position: absolute;
  top: 0;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s;
  height: 17px;
  width: 12px;

  .products-narrow-down & {
    left: 0;
    bottom: 0;
    margin: auto;
  }

  .filter-word-wrap & {
    height: 15px;
    width: 15px;
  }
}

.checkbox-button+label::after,
.checkbox-button+label::before {
  border-radius: 3px;
}

.radio-button+label::before,
.radio-button+label::after {
  border-radius: 50px;
}

.checkbox-button:checked+label::after,
.radio-button:checked+label::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 1;
  transform: scale(1);
  z-index: 0;

  .products-narrow-down & {
    height: 20px;
    width: 20px;
  }

  .filter-word-wrap & {
    height: 15px;
    width: 15px;
  }
}

//---------------------------------
//プルダウン
.select-wrap {
  height: 42px;
  position: relative;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-gray-pale);
  z-index: 1;

  .product-review & {
    height: 48px;
  }

  &:after {
    @include arrow(bottom, 3px, var(--color-gray));
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: -1;
  }

  select {
    height: 100%;
    @include gentle-flex;
    gap: var(--gutter);
    padding: 0 30px;
    border: none;
    background: none;
    appearance: none;
    outline: none;
    text-align: center;
    font-weight: bold;
  }
}

///* ドロップダウン
select.select-dropdown {
  text-align: left;
}

select.select-dropdown option {
  text-align: left;
}

//スウィッチ
.switch {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 128px;
  height: 40px;
  padding: 0;
  background-color: var(--color-white);
  border-radius: 50px;

  .switch-left,
  .switch-right {
    position: relative;
    width: 50%;
    height: 100%;
    border: 1px solid var(--color-gray-slategray);

    &:before {
      background-color: var(--color-gray-slategray);
      width: 24px;
      height: 24px;
      &:hover {
        background-color: var(--color-primary);
      }
    }
    [data-fl-tooltip-ref]{
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .switch-left {
    border-style: solid none solid solid;
    border-radius: 50px 0 0 50px;
    &:before {
      @include svgMaskIcon("block");
    }
  }

  .switch-right {
    border-style: solid solid solid none;
    border-radius: 0 50px 50px 0;

    &:before {
      @include svgMaskIcon("table");
    }
  }

  .slider {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 50%;
    height: 100%;
    transition: 0.4s;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    z-index: 1;

    &:before {
      position: absolute;
      content: "";
      transition: 0.4s;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--color-white);
    }

    [data-fl-tooltip-ref]{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

.slider.list {
  border-style: solid solid solid none;
  border-radius: 0 50px 50px 0;
  transform: translateX(65px);

  &:before {
    @include svgMaskIcon("table");
  }
}

.slider.all {
  border-style: solid none solid solid;
  border-radius: 50px 0 0 50px;
  transform: translateX(0);

  &:before {
    @include svgMaskIcon("block");
  }
}

/* カスタムセラクタ */
.custom-select-wrapper {
  position: relative;
  user-select: none;

  .custom-select {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .custom-select-trigger {
    position: relative;
    @include gentle-flex(center, space-between);
    gap: var(--gutter);
    padding: 0 22px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
    color: var(--color-black);

    .side-custom-select & {
      display: block;
      max-width: 300px;

      @media #{$sp} {
        max-width: 100%;
      }
    }

    span {
      color: var(--color-black);
    }

    &:before {
      position: absolute;
      top: 50%;
      right: 10px;
      @include svgIcon("icon-upside-down-triangle");
      width: 14px;
      height: 7px;
      margin-right: 5px;
      cursor: pointer;
      transition: transform 0.3s ease;

      .side-custom-select & {
        @include svgIcon("icon-arrow-bottom-black");
        width: 9px;
        height: 14px;
        top: 15px;
      }
    }

    &.open:before {
      transform: rotate(180deg);
    }

    &.open+.custom-select .custom-options {
      display: block;
      opacity: 1;
      visibility: visible;
    }

    &.inner-select {
      padding: 0;

      select {
        padding: 16px 22px;
      }
    }
  }

  .custom-options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--color-white);
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    max-height: 250px;
    overflow-y: auto;
    position: relative;

    .custom-option {
      position: relative;
      display: block;
      padding: 10px 22px;

      color: var(--color-gray-bluette);
      cursor: pointer;

      &:hover {
        color: var(--color-white);
        background: var(--color-primary);
      }
    }

    .non-selectable {
      cursor: default;
      color: #999;
      pointer-events: none;
    }

    .custom-option-group {
      .custom-option {
        padding-left: 40px;
      }
    }
  }
}

//タグ
.tag-cmn {
  display: inline-flex;
  overflow: hidden;

  &.min {
    a {
      padding: 2px 10px;
    }
  }
}

.tag-count {
  padding: 5px;
  border-radius: var(--border-radius);
  font-size: 14px;
  background: var(--color-gray-light);
}

.tag-product,
.tag-category,
.tag-campaign,
.tag-review {
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;

  &.simple:hover {
    border-radius: 30px;
  }
}

.tag-review {
  white-space: nowrap;
  align-self: flex-start;
  height: fit-content;

  //TODO：比較のときに親にクラス名つける
  .compar-products & {
    margin-top: -10px;
    margin-right: -10px;
  }

  .user-products & {
    position: absolute;
    right: 10px;
  }

  @media #{$sp} {
    margin-top: 0;
    margin-right: 0;
    align-self: center;
  }
}

////プロダクトー製品一覧
.product-card-content {
  .product-card-wrap {
    display: flex;
    padding: 16.5px 24px 24px 16px;
    border: 2px solid var(--color-gray-pale);
    &:has(.compare-checkbox) {
      padding: 16.5px 24px 24px 56px;
    }

    @media #{$sp} {
      flex-direction: column;
      &:has(.compare-checkbox) {
        padding: 60px 15px 24px 15px;
      }

      >.input-wrap {
        background: var(--color-primary-bright);
        padding: 5px;
        border-radius: var(--border-radius);

        p {
          margin-left: 10px;
        }
      }
    }

    &.active {
      border: 2px solid var(--color-primary);
      .compare-checkbox {
        background-color: var(--color-primary-bright);
      }
    }

    .compare-checkbox {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      max-width: 56px;
      max-inline-size: 56px !important;
      background-color: var(--color-gray-pale);
      @media #{$sp} {
        height: 50px;
        width: 100%;
        max-width: 100%;
        max-inline-size: 100% !important;
      }

      &:hover {
        color: var(--color-primary)
      }
    }
    .product-card {
      width: 100%;
      margin-left: 16px;
      margin-top: 7.5px;
      @media #{$sp} {
        margin: 0;
      }

      .product-card-badge {
        position: absolute;
        top: -8px;
        right: -20px;
    
        @media #{$sp} {
          top: -8px;
          right: -10px;
    
          img {
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
          }
        }
      }

      .switch-change-big {
        .product-card-btn-wrap {
          @media #{$sp} {
            margin: 10px 0 30px;

          }
        }
      }

      .switch-change-min {
        .star-score {
          max-width: 200px;
        }
        @media #{$sp} {
          .sp-score {
            margin-top: 10px;
          }
        }
      }

      .list-price li {
        &::after {
          display: inline-block;
          content: "/";
          padding: 0 10px;
        }

        &:last-child::after {
          display: none;
        }
      }
    }
  }
}

//---------------------------

/*キーワードから探す */

//---------------------------
.search-keyword {
  .logo-wrap-100 {
    @media #{$sp} {
      min-width: 50px;
      max-width: 50px;
      height: 50%;
      min-height: 50px;
      max-height: 50px;
    }
  }
}

//---------------------------
//チェンジ
.switch-change-wrap {
  &.active {
    >img {
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
    }

    .product-name {
      font-size: 18px;
    }

    .product-co {
      font-size: 12px;
    }
  }
}

.category-index {
  padding: 15px;
  line-height: 1;
  background-color: var(--color-gray-light);

  &:before {
    content: "目次";
    display: block;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
  }

  .index-item,
  .index-child {
    cursor: pointer;
  }

  .index-item {
    margin: 25px 0 20px;
    font-weight: bold;
    line-height: 1.2;
    @media #{$sp} {
      text-indent: -30px;
      padding-left: 30px;
    }

    &:before {
      content: "●";
      font-size: 12;
      padding-right: 12px;
      color: var(--color-primary);
    }
  }

  .index-child {
    padding-left: 30px;
    line-height: 1.2;
    & + .index-item {
      @media #{$sp} {
      margin-top: 30px;
      }
    }
  }
}

//--------------------------------------------------------------------------------------------*/

/* プロダクト */

//---------------------------
.product-top-wrap {
  @media #{$sp} {
    margin-top: 20px;

    .sp-block {
      width: 100%;
      @include gentle-flex(center, flex-start);
      margin-top: 15px;
    }
  }

  .product-banner {
    position: relative;
    overflow: hidden;
    display: block;
    width: 980px;
    max-height: 340px;
    aspect-ratio: 1280 / 340;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: top;
    }

    &.add-banner {
      @include gentle-flex(center, flex-end);

      @media #{$sp} {
        display: none;
      }

      img {
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .product-top-logo-wrap {
    width: calc(var(--main-content-width) - 980px);
    @include gentle-flex();

    @media #{$sp} {
      width: 120px;
      margin-right: 10px;
    }

    .product-top-logo {
      padding: 5px;
      box-sizing: content-box;
      position: relative;

      @media #{$sp} {
        width: 100%;
        min-width: 100px;
        max-width: 100px;
        min-height: 100px;
        max-height: 100px;

        a,
        img {
          min-width: 100px;
          max-width: 100px;
          min-height: 100px;
          max-height: 100px;
        }
      }

      .product-top-badge {
        position: absolute;
        top: -20px;
        right: -20px;

        @media #{$sp} {
          top: -20px;
          right: -20px;

          img {
            min-width: 50px;
            max-width: 50px;
            min-height: 50px;
            max-height: 50px;
          }
        }
      }
    }
  }
}

.product-btn-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 15px;

  @media #{$sp} {
    justify-content: left;
    margin-top: 15px;
  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .icon-mylist {
    @include gentle-flex();
    margin: 0;
    border-radius: var(--border-radius);
    .icon-only {
      width: 40px;
      padding: 0;
    }
  }

  .review-btn-wrap.check-user {
    .icon-review.product-review-btn {
      width: max-content;
    }
  }
}

.product-card-btn-wrap {
  >div {
    height: auto;

    a {
      width: auto;
      height: 40px;
      padding: var(--gutter-ss) var(--gutter-l);
      @include gentle-flex();
    }
  }
}

//バーグラフ
.product-rating-wrap,
.scale {
  .products-narrow-down & {
    z-index: 1;
  }

  flex: 3;

  @media #{$sp} {
    font-size: 12px;
  }
}

.progress-wrap {
  flex: 7;

  .feature-list-value & {
    flex: 18;

    @media #{$sp} {
      flex: 10;
    }
  }

  .progress {
    height: 8px;
    border-radius: 5px;
    background: var(--color-gray-light);

    .progress-meter {
      position: relative;
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 15px;
      transition: width 0.3s ease-in-out;
      background: var(--color-gradient-graph);
      border: 1px solid var(--color-gray-bright);

      .products-narrow-down & {
        z-index: 1;
      }

      .feature-list-value &,
      .ranking-list-value & {
        height: 10px;
        background: var(--color-gradient-value);
      }

      &-reverse-overlay {
        background-color: var(--color-gray-light);
        bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
      }

      .tooltip {
        @include tooltip;
        top: 100%;
        z-index: 3 !important;
      }

      &:hover .tooltip {
        display: block;
      }
    }
  }

  &+span {
    flex: 1;
    text-align: right;

    .products-narrow-down & {
      z-index: 1;
    }
  }
}

.feature-list-value,
.ranking-list-value {
  .progress {
    position: relative;
  }

  .progress::before,
  .progress::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--color-gray-light);
    z-index: 1;
  }

  .progress::before {
    left: 20%;
  }

  .progress::after {
    left: 40%;
  }

  .progress-meter::before,
  .progress-meter::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--color-gray-light);
    z-index: 1;
  }

  .progress-meter::before {
    left: 60%;
  }

  .progress-meter::after {
    left: 80%;
  }

  .progress-meter-separator {
    border: 2px solid var(--color-black);
    border-radius: 100px;
    box-shadow: 0 0 0 2px #fefefe;
    box-sizing: border-box;
    height: 24px;
    position: absolute;
    top: -8px;
    width: 1px;
    z-index: 3;
    font-size: 12px;

    &:after {
      content: "平均";
      position: absolute;
      top: 22px;
      left: -10px;
      display: block;
      font-size: 12px;
      width: 24px;
    }
  }
}

//動画サムネイル
.movie-cover {
  @include gentle-flex();
  height: 170px;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.3);
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include svgIcon("icon-play");
    width: 52px;
    height: 52px;

    .product-img & {
      content: none;
    }
  }
}

.youtube-video {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-cover {
  position: relative;
  aspect-ratio: 16/9;
  overflow: hidden;
  margin-bottom: 15px;
  cursor: pointer;
}

.youtube-thumbnail {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  @include svgIcon("twitter");
  background-size: 50px;
  transform: translate(-50%, -50%);
  z-index: 2;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}

//スクロール
.move-block {
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;

  @media #{$sp} {
    overflow-x: scroll;
  }

  .box {
    flex-shrink: 0;
    scroll-snap-align: start;
    margin-right: 10px;
  }
}

.dots {
  display: flex;
  justify-content: center;
  z-index: 2;

  @media #{$sp} {
    display: none;
  }

  .dot {
    all: unset;
    margin-right: 5px;
    width: 20px;
    height: 6px;
    border-radius: 10px;
    background: var(--color-black);
    margin-top: 10px;

    &.active {
      background: var(--color-primary);
      outline: 1px solid var(--color-primary);
    }
  }
}

//モーダル（動画仕様）
.modal-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: map-get($z-index, modal);

  .modal-content {
    width: 100%;
    max-width: 600px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: var(--color-white);
    border: var(--border-radius);
    &.products-thumbnail {
      max-width: 1000px;
    }
    .modal-close-btn {
      position: absolute;
      top: -9px;
      right: 5px;

      &:before {
        @include svgIcon("icon-cross-circle");
        width: 48px;
        height: 48px;
      }
    }

    .arrow {
      position: absolute;
      top: 50%;

      &.right {
        right: -80px;
      }

      &.left {
        left: -80px;
      }

      &.inactive {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

/* モーダルの基本スタイル */
.modal-cmn {
  position: fixed;
  z-index: map-get($z-index, modal);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  gap: 1ch;
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    margin: 10% auto;
    padding: 30px;
    width: 100%;
    height: 100%;
    max-width: 884px;
    min-height: 600px;
    max-height: 600px;
    border-radius: var(--border-radius);
    background: var(--color-white);
    position: relative;
    top: -10%;

    /* iPad Air 縦向き時に適用 */
    @media screen and (min-width: 820px) and (max-width: 1199px) and (orientation: portrait) {
      width: 90%;
      top: -20%;
    }

    @media #{$sp} {
      max-width: 90%;
      padding: 15px;
      min-height: 70vh;
      max-height: 70vh;
    }

    &.min {
      margin: 0 auto;
      padding: 15px;
      min-height: auto;
      top: 0;
    }

    .close {
      position: absolute;
      top: -20px;
      right: 0;
      margin-right: -20px;
    }

    .close:hover,
    .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    .remove-button {
      position: absolute;
      top: -20px;
      right: 0;
      margin-right: -20px;
    }

    .modal-content-inner {
      margin-right: -10px;
      padding-right: 10px;
      padding-top: 15px;

      @media #{$sp} {
        max-height: 90%;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .icon-plus-circle {
        width: 30px;
      }
    }
  }
}

//電話認証コード
.authcode .iti--separate-dial-code {
  width: 100%;
}

//資料ダウンロード
.doc-card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gutter);

  @media #{$sp} {
    display: flex;
    overflow-x: scroll;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    width: calc(100vw - 30px);
  }

  li {
    @media #{$sp} {
      flex: 0 0 80%;
      max-width: 300px;
      height: auto;
    }

    &:first-of-type {
      grid-column: 1 / 4;

      @media #{$sp} {
        grid-column: 1;
      }

      img {
        width: 100%;
        max-width: 270px;
        height: 100%;
        max-height: 350px;

        @media #{$sp} {
          max-width: 100%;
          max-height: 200px;
          margin: 0 auto;
        }
      }

      .block {
        display: flex;
        align-items: flex-start;
        flex-grow: 1;

        @media #{$sp} {
          flex-direction: column;
        }

        &:nth-child(2) {
          flex-direction: column;
        }
      }

      @media #{$sp} {
        flex-direction: column;
        align-items: center;
      }
    }

    &:not(:first-child) {
      .block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
          width: 100%;
          max-width: 270px;
          height: auto;
          max-height: 202.5px;

          @media #{$sp} {
            max-width: 100%;
            max-height: 202.5px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

//テーブル用スクロール（通常はover-x-auto　Todo:でもここは整理必要かも）
.scroll-x {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: grid;
  flex: 0 0 auto;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  @media #{$sp} {
    &.w-90vw {
      width: 90vw;
    }

    &.w-100vw {
      width: 100vw;
    }
  }
}

//スマホのみ
@media #{$sp} {
  .sp-scroll-x {
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    flex: 0 0 auto;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }
}

.table-area {
  @media #{$sp} {
    display: block;
    width: 300px;
    min-width: 100%;
  }
}

//価格テーブル
table {
  width: 100%;
  margin-bottom: 0.75rem;
  border-radius: 2px;

  .editions-tbody {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    gap: 30px;

    &-space {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--gutter);

      @media #{$sp} {
        grid-template-columns: auto;
      }
    }
  }

  .editions-tr {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: var(--border-radius);
    background: var(--color-gray-light);

    @media #{$sp} {
      width: 100%;
    }

    &:nth-child(4n) {
      border-right: 1px solid var(--color-white-off);
    }

    &:last-child {
      border-right: 1px solid var(--color-white-off);
    }

    td {
      border-bottom: 1px solid var(--color-white-off);
    }
  }

  .editions-td {
    @include gentle-flex;
    background: var(--color-gray-light);
  }

  .editions-pricing {
    width: unset;
    text-align: center;
    @include gentle-flex;
  }

  .editions-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-break: break-word;

    .editions-company-size {
      background: transparent;
    }
  }

  .editions-explanation {
    &-check {
      padding-left: 30px;
      text-indent: -46px;
      margin-left: 10px;

      &:before {
        @include svgIcon("icon-check-black");
        width: 14px;
        height: 10px;
        margin: 0 10px 0 20px;
      }
    }
  }
}

//比較Table
table {
  .compare-tbody {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    border-radius: var(--border-radius);
    border: 1px solid var(--color-gray-pale);

    .table-ovreflow & {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      display: grid;
      grid-auto-flow: column;
    }

    @media #{$sp} {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
    }
  }

  .compare-tr {
    display: grid;
    grid-template-rows: subgrid;
    position: relative;
    background: var(--color-gray-light);

    @media #{$sp} {
      flex: 0 0 auto;
      width: 100%;
      max-width: 250px;
    }

    td {
      height: 100%;
      @include gentle-flex;
      flex-direction: column;
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: normal;
    }

    .table-ovreflow & {
      width: 100%;
      min-width: 200px;
      border-right: 1px solid var(--color-gray-pale);

      &:first-child {
        left: 0;
        position: sticky;
        z-index: 1;
        border-radius: 5px 0 0 0;
      }

      td:first-child {
        background: var(--color-white);
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .compare-td {
    @include gentle-flex;
    flex-direction: column;
    height: 100%;
    word-break: break-all;
    white-space: normal;
    align-self: stretch;

    @media #{$sp} {
      font-size: 12px;
    }

    &:nth-child(2n) {
      background: var(--color-white);
    }

    .table-ovreflow & {
      background: var(--color-gray-light);

      &:nth-child(2n + 1) {
        background: var(--color-white);
      }
    }
  }

  .compare-pricing {
    width: unset;
    @include gentle-flex;
    gap: var(--gutter);
    justify-content: center;
    background: var(--color-gray-light);
  }

  .compare-description {
    display: flex;

    flex-direction: column;

    justify-content: space-between;
    word-break: break-word;
  }
}

//右に矢印でスクロール用
.table-ovreflow-wrap {
  position: relative;

  .table-ovreflow {
    width: 100%;
    max-width: var(--wrapper-width);
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overscroll-behavior: contain;
    margin-left: 1px;

    @media #{$sp} {
      width: 300px;
      min-width: 100%;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
    }

    .right-arrow {
      right: 0;
      @include svgIcon("icon-arrow-right");
      width: 48px;
      height: 48px;
    }

    .left-arrow {
      left: 190px;
      @include svgIcon("icon-arrow-left");
      width: 48px;
      height: 48px;
    }

    .table-over {
      width: 200px;
      font-size: 14px;
      padding: 5px;
      position: absolute;
      background: var(--color-white);
      border-radius: var(--border-radius);
      border: 1px solid var(--color-gray-pale);
      z-index: 2;
    }
  }
}

//製品で比較のテーブル
.featur-compare-table {
  max-width: var(--wrapper-width);
  position: relative;

  .comparison-table-wrap & {
    max-width: 100%;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    white-space: normal;
  }

  #featur-compare & {
    width: 100%;
  }

  thead {
    //TODO:他をみて問題ないなら消す
    // position: sticky;
    // top: 0;
    // z-index: 2;
    height: 30px;
    font-size: 12px;

    th {
      line-height: 1;
      text-align: center;
      min-width: 150px;

      &:first-child {
        left: 0;
        position: sticky;
        z-index: 1;
        background-color: var(--color-white);

        @media #{$sp} {
          min-width: 80px;
        }
      }

      .comparison-table-wrap & {
        >div {
          border-bottom: none;

          +div {
            border-left: none;
          }
        }
      }
    }
  }

  tbody {
    font-weight: bold;

    tr {
      border: 1px solid var(--color-white);
    }

    td {
      vertical-align: middle;
      min-width: 100px;
      height: 42px;
      text-align: center;
      border: 1px solid var(--color-white);

      .comparison-table-wrap & {
        padding: 15px;
        border: 1px solid var(--color-gray-pale);
        font-size: 20px;
      }

      #featur-compare & {
        min-width: 128px;
      }

      &:first-child {
        min-width: 190px;
        max-width: 190px;
        padding-right: 10px;
        table-layout: fixed;
        left: -1px;
        position: sticky;
        z-index: 1;
        font-size: 14px;
        text-align: left;
        background-color: var(--color-white);
        pointer-events: auto;
        z-index: 3;

        .comparison-table-wrap & {
          max-width: 335px;
          min-width: auto;
          text-align: left;

          @media #{$sp} {
            font-size: 12px;
          }
        }
      }

      &:not(:first-child):hover {
        position: relative;

        .table-over {
          display: block;
        }
      }
    }

    .table-over {
      display: none;
    }
  }
}

//王冠
td:has(.crown) {
  position: relative;
}

.table-cell.crown:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  @include svgIcon("icon-crown");
  width: 22px;
  height: 22px;

  #featur-compare-table & {
    top: 35px;
  }

}

//レビュー
.product-review {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  @media #{$sp} {
    grid-template-columns: 1fr;
  }

  .search-box {
    position: relative;

    .search-word-wrap {
      position: absolute;
      top: 50px;
      left: 0;
      padding-top: 30px;
      z-index: 1;
    }
  }
}

.review {
  &.review-card {
    min-height: 600px;
  }

  .first-sentence {
    min-height: 190px;
  }

  >div:first-child {
    margin: 0 -24px;
    padding: 0 24px 24px;
    border-bottom: 1px solid var(--color-gray-pale);

    @media #{$sp} {
      margin: 0 -12px;
      padding: 0 12px 24px;
    }
  }

  &.blur {
    .blur-wrap {
      position: relative;
      height: 100%;
      min-height: 400px;

      &::after {
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        content: "";
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        display: block;
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }

    .register-btn-wrap {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      .register-btn-list {
        width: 364px;
        --size-content: 364px;

        li {
          @media #{$sp} {
            width: 300px;
          }
        }
      }
    }
  }

  &.attention {
    border-color: var(--color-yellow);
    padding-top: 30px;

    >div:first-child {
      border-color: var(--color-yellow);
    }

    .review-ribbon {
      &:before {
        @include svgIcon("ribbon-yellow");

        width: 240px;
        height: 32px;

        @media #{$sp} {
          width: 184.1px;
          height: 23.8px;
        }

        &:after {
          @include svgIcon("ribbon-yellow");

          width: 180px;
          height: 34px;
          margin-right: 10px;

          @media #{$sp} {
            width: 126px;
            height: 23.8px;
          }
        }
      }
    }
  }

  &.pick-up {
    border-color: var(--color-primary);
    padding-top: 30px;

    >div:first-child {
      border-color: var(--color-primary);
    }

    .review-ribbon {
      &:before {
        @include svgIcon("ribbon-pink");

        width: 240px;
        height: 32px;

        @media #{$sp} {
          width: 184.1px;
          height: 23.8px;
        }

        &:after {
          @include svgIcon("ribbon-pink");

          width: 180px;
          height: 34px;
          margin-right: 10px;

          @media #{$sp} {
            width: 126px;
            height: 23.8px;
          }
        }
      }
    }
  }

  // レビューをシェアのホバー時 / 他はaタグだが、シェアだけdivタグなので個別に指定
  .icon-share:hover {
    background: var(--color-primary-bright);
  }
}

//ベンダーの返信
.vendor-official {
  border: 2px solid var(--color-primary);

  >div:first-child {
    border-bottom: 2px solid var(--color-primary);
  }
}

//レビューのリボン
.ribbin-wrap {
  position: relative;

  .review-ribbon {
    position: absolute;
    top: 0;
    right: 0;
    @include gentle-flex;
    gap: var(--gutter);

    @media #{$sp} {
      top: -18px;
    }

    .review-ribbon-inner {
      position: absolute;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: var(--color-white);

      @media #{$sp} {
        font-size: 12px;
      }

      .ribbon-icon {
        margin-right: 5px;
        @include gentle-flex;
        gap: var(--gutter);
        background-color: var(--color-white);
        border-radius: var(--border-radius);

        @media #{$sp} {
          width: 20px;
          height: 20px;
          min-width: 20px;
          max-width: 20px;
          min-height: 20px;
          max-height: 20px;
        }
      }
    }
  }
}

//レビューにつくチャリティーバッジ
.icon-charity {
  position: absolute;
  bottom: 0;
  right: 0;

  &+.tooltip-description {
    top: 35px;
    left: 20px;

    &:after {
      left: 30px;
    }
  }
}

//レビュー＆コメント
.comment-area {
  margin: 20px 0 0;

  div.btn.secondary {
    margin-left: 10px;
    width: 60px;
    padding: 5px;
    text-align: center;
    font-size: 12px;
    color: var(--color-white);
    border-radius: var(--border-radius);
    background: var(--color-black);
    cursor: pointer;
  }
}

//レビューの絞り込み
.review-filter-wrap {
  position: relative;

  @media #{$sp} {
    width: 100%;
  }

  .review-filter:hover {
    color: var(--color-primary);
    cursor: pointer;

    img {
      @extend %svg-fil-primary;
    }
  }

  .filter-word-wrap {
    width: var(--wrapper-width);
    position: absolute;
    top: 50px;
    left: 0;
    padding-top: 30px;
    z-index: map-get($z-index, word-wrap);
    background: var(--color-white);
    display: none;

    @media #{$sp} {
      width: 100%;
    }

    &.active {
      display: block;
    }

    .checkbox-button+label {
      top: auto;
      margin-right: 5px;
    }

    .filter-list {
      position: relative;
      max-width: 305px;

      span {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .industry-wrap {
      width: calc(100% * 2 / 3);

      @media #{$sp} {
        width: 50%;
      }

      .industry-list {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(10, auto);
        gap: 0 0.625rem;

        @media #{$sp} {
          grid-auto-flow: row;
        }

        li:nth-child(11) {
          margin-top: 0;

          .checkbox-button+label {
            top: 6px;
          }
        }
      }
    }

    .position-list {
      width: calc(100% * 1 / 3);

      @media #{$sp} {
        width: 50%;
      }
    }
  }
}

//競合製品、TOP20
.lets-competing {
  @media #{$sp} {
    .block {
      margin-bottom: 10px;
    }
  }

  p {
    line-height: 1.4;
  }
}

.competing-product {
  @media screen and (min-width: 1140px) and (max-width: 1200px) {
    .side {
      max-width: 40px;
      max-inline-size: 40px !important;

      P {
        font-size: 18px;

        span {
          font-size: 20px;
        }
      }

    }
  }

  @media screen and (min-width: 300px) and (max-width: 1200px) {
    .competition-block {
      &.pc-only {
        display: none;
      }

      &.sp-tablet-only {
        display: flex !important;
      }

    }
  }

  @media #{$sp-tablet} {
    article {
      max-width: 100%;
      ;
      max-inline-size: 100% !important;
    }
  }

  @media #{$sp} {
    .competing-check {
      position: unset;

      .input-box {
        margin-right: 5px;
      }
    }

    article {
      margin-bottom: 10px;
    }
  }

  p {
    line-height: 1.6;
    width: auto;
  }

  .valuation-ribbon {
    display: inline-block;
    position: relative;
    margin-left: -10px;
    left: 0;
    height: 34px;
    line-height: 34px;
    text-align: center;
    padding: 0 40px 0 18px;
    font-size: 14px;
    background: var(--color-primary);
    color: var(--color-white);
    box-sizing: border-box;

    @media only screen and (min-width: 1143px) and (max-width: 1240px) {
      font-size: 12px;
      font-weight: bold;
    }

    @media #{$sp} {
      height: 24px;
      line-height: 24px;
      font-size: 10px;
      padding: 0 30px 0 10px;
    }

    &:after {
      position: absolute;
      content: '';
      width: 0px;
      height: 0px;
      top: 0;
      right: 0;
      border-width: 17px 15px 17px 0px;
      border-color: transparent var(--color-gray-light) transparent transparent;
      border-style: solid;

      @media #{$sp} {
        border-width: 12px 10px 12px 0px;
      }
    }
  }

  @media #{$sp} {
    .grid-list--3 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

//受賞実績
.award-list {
  li {
    display: grid;
    align-items: center;
    position: relative;
    height: 72px;
    padding: 0 20px;
    color: var(--color-white);
    font-size: 22px;
    font-weight: bold;
    overflow: hidden;
    border-radius: var(--border-radius);

    @media #{$sp} {
      height: 85px;
      padding: 0 10px;
      font-size: 16px;
    }

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

//レーダーチャート
.chart-container {
  position: relative;
  width: 100%;
  max-width: 270px;
  display: flex;
  justify-content: center;

  margin: 0;
  padding: 5px 0;

  thead & {
    padding: 0;
  }

  &:hover .hover-chart-container {
    display: block;
  }

  .hover-chart-container {
    display: none;
    width: 400px;
    margin: 0;
    position: absolute;
    right: -100%;
    z-index: 999;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
    box-sizing: content-box;
  }

  //TODO:なんだっけ、これ。。。ｗ　他をみて問題ないあら消す
  // tbody {
  //   overflow-x: auto;
  //   white-space: nowrap;
  //   -ms-overflow-style: -ms-autohiding-scrollbar;
  //   display: flex;
  //   flex: 0 0 auto;
  //   -ms-scroll-snap-type: x mandatory;
  //   scroll-snap-type: x mandatory;
  // }
}

#myChart {
  @media #{$sp} {
    width: 200px !important;
    height: 200px !important;
  }
}

//製品紹介文
.product-intro {
  margin-top: 30px;
  border-top: 1px solid var(--color-gray-pale);

  @media #{$sp} {
    .sidebar {
      flex-basis: auto;
    }
  }

}

//「販売終了」「製品統合」「リニューアル」フラグ
.product-status-message {
  text-align: center;
  font-weight: 700;
  font-size: 24p;

  @media #{$sp} {
    font-size: 16px;
  }

  &.sales_end {
    background: var(--color-gray-pale);
  }

  &.renewal,
  &.integrated {
    background: var(--color-primary);
    color: var(--color-white);

    a {
      display: inline;
      color: var(--color-white);
      text-decoration: underline;
    }
  }
}

.product-card-content {
  .product-card-wrap {
    position: relative;

    &:has(.sales_end) {
      background: var(--color-gray-pale);
    }

    .sales_end.triangle,
    .integrated.triangle,
    .renewal.triangle {
      position: absolute;
      top: 0;
      right: 0;
      border-bottom: 150px solid transparent;
      border-right: 150px solid var(--color-gray);

      @media #{$sp} {
        position: unset;
        padding: 5px;
        text-align: center;
        border-bottom: none;
        border-right: none;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        @media #{$sp} {
          display: none;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 15px;
        right: 0;
        transform: rotate(45deg);
        color: var(--color-white);
        z-index: 2;

        @media #{$sp} {
          display: none;
        }
      }

      .label {
        position: absolute;

        z-index: 3;
        color: var(--color-white);

        @media #{$sp} {
          position: unset;
        }
      }

      &.sales_end {
        @media #{$sp} {
          background: var(--color-gray);
        }

        .label {
          top: 30px;
          right: -130px;
          color: var(--color-white);
        }
      }

      &.integrated,
      &.renewal {
        border-right-color: var(--color-primary);

        @media #{$sp} {
          background: var(--color-primary);
        }

        .label {
          top: 30px;
          right: -130px;

        }
      }

      &.renewal .label {
        right: -145px;
      }

      &.switch-change-min {
        top: -15px;
        padding: 5px 10px;
        text-align: center;
        border-bottom: none;
        border-right: none;
        border-radius: 30px;

        &::before,
        &::after {
          display: none;
        }

        .label {
          position: unset;
          font-size: 12px;
        }

        &.sales_end {
          background: var(--color-gray);
        }

        &.integrated,
        &.renewal {
          background: var(--color-primary);
        }
      }
    }
  }
}

//---------------------------------

//list-disc
.list-disc {
  margin-left: 15px;
  list-style-type: disc;
  color: var(--color-primary);

  p {
    color: var(--color-black);
  }
}

//ドーナツチャート
#doughnutchart {
  max-width: 426px !important;
  width: 426px !important;
  height: 426px !important;

  @media #{$sp} {
    width: 300px !important;
    height: 300px !important;
  }

  &+div {
    display: none;
  }
}

.doughnutchar-price {
  width: 100%;
  max-width: 426px;

  li {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 3px;
    position: relative;

    span:first-child {
      width: 100px;
      margin-left: 18px;
    }

    &::before {
      content: "●";
      padding-right: 5px;
      font-size: 10px;
      position: absolute;
      left: 0;
    }

    &:first-child::before {
      color: #e50060;
    }

    &:nth-child(2)::before {
      color: #ffbb44;
    }

    &:nth-child(3)::before {
      color: #11aa3a;
    }

    &:nth-child(4)::before {
      color: #36bcd9;
    }

    &:nth-child(5)::before {
      color: #5544aa;
    }

    &:nth-child(6)::before {
      color: #ffccdd;
    }

    &:nth-child(7)::before {
      color: #ffe0ab;
    }

    &:nth-child(8)::before {
      color: #ccffe6;
    }

    &:nth-child(9)::before {
      color: #ccefff;
    }

    &:nth-child(10)::before {
      color: #deccff;
    }

    &:nth-child(11)::before {
      color: var(--color-gray-pale);
    }
  }
}

//---------------------------------
//ボタンアイコン

.icon-mylist {
  color: var(--color-black);
  background: var(--color-white);
  border: 1px solid var(--color-gray-pale);
  margin-top: 0 !important;
  border-radius: var(--border-radius);
  gap: var(--gutter-ss);

  &:hover {
    color: var(--color-primary);
    background: var(--white);
  }
  &:before {
    @include svgIcon("icon-heart");
    width: 20px;
    height: 18px;
    @extend %svg-fil-primary;
  }

  .js-bookmark.active & {
    border: 1px solid var(--color-primary);

    &:before {
      @include svgIcon("icon-heart-fill");
      @extend %svg-fil-primary;
    }
  }

  .product-card-btn-wrap & {
    @include gentle-flex();
    margin: 0;
    font-size: 14px;
    border-radius: var(--border-radius);

    &:before {
      width: 16px;
      height: 16px;
    }

    a {
      width: 100%;
      height: 100%;
      z-index: 1;
      font-weight: bold;
    }
  }

  &.inner-text {
    width: auto;
    height: auto;

    &:before {
      margin-right: 10px;
    }
  }
}

svg.icon-info {
  width: 20px;
  height: 20px;
}

.icon-site {
  margin-top: 0 !important;

  .sidebar & {
    max-inline-size: none;
    width: 100%;
    flex: auto;
    position: relative;
  }

  &:after {
    @include svgIcon("icon-external-link");
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }

  .sidebar & {
    &:after {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.icon-contact {
  margin-top: 0 !important;

  .sidebar & {
    max-inline-size: none;
    width: 100%;
    flex: auto;
  }

  &:before {
    @include svgIcon("icon-mail-white");
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .product-action-btn & {
    &:before {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.icon-secure {
  margin-top: 0 !important;

  .sidebar & {
    max-inline-size: none;
    width: 100%;
    flex: auto;
    position: relative;
  }

  &:after {
    @include svgIcon("icon-secure");
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }

  .sidebar & {
    &:after {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.icon-download {
  
  .sidebar & {
    margin-top: 0 !important;
    max-inline-size: none;
    width: 100%;
    flex: auto;
    position: relative;
  }

  &:after {
    @include svgIcon("icon-dl");
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }

  .sidebar & {
    &:after {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.icon-review {
  @include gentle-flex();
  width: 40px;
  height: 40px;

  .check-user & {
    width: 100%;
    height: 100%;

    a {
      width: 100%;
      height: 100%;

      &:hover {
        color: var(--color-primary);
        background: var(--color-primary-bright);
      }
    }

  }

  a {
    background: var(--color-black);
    border-radius: var(--border-radius);
    position: relative;

    &:before {
      position: absolute;
      @include svgIcon("icon-edit");
      width: 24px;
      height: 24px;
    }
  }

  &.product-review-btn {
    a {
      @include gentle-flex();
      font-size: 14px;
      font-weight: bold;
      color: var(--color-white);
      padding-left: 40px;

      &:before {
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.icon-mgmt {
  &:before {
    @include svgIcon("icon-mgmt");
    width: 17px;
    height: 20px;
    margin-right: 10px;
  }

  .sidebar & {
    &:before {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.icon-dl {
  font-size: 14px;

  &:after {
    @include svgIcon("icon-dl");
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
}

.icon-play {
  &:after {
    @include svgIcon("icon-play");
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
}

.icon-check {
  padding: 2px 10px;
  border-radius: 30px;

  &:before {
    @include svgIcon("icon-check-red");
    width: 8px;
    height: 17px;
    margin-right: 3px;
  }
}

.btn-more {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:after {
    @include svgIcon("icon-arrow-bottom-gray");
    width: 14px;
    height: 14px;

    transition: transform 0.2s cubic-bezier(0.2, 0.6, 0.3, 1.1);
  }

  &.js-close:after {
    @include svgIcon("icon-arrow-bottom-gray");
    transform: rotate(180deg);
  }
}

.icon-comment {
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:before {
    @include svgIcon("icon-comment");
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
}

.icon-share {
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:before {
    @include svgIcon("icon-share");
    width: 15px;
    height: 16px;
    margin-right: 5px;
  }
}

.icon-vote {
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:before {
    @include svgIcon("icon-good");
    width: 17px;
    height: 16px;
    margin-right: 5px;
  }
}

.sns-share-button {
  width: 54px;
  height: 54px;
  @include gentle-flex();
  gap: var(--gutter);
  border-radius: var(--border-radius);
  background: var(--color-primary-bright);
}

//シェア
.product-balloon-wrap {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);

  .product-balloon {
    position: relative;
    display: block;
    padding: 0 2px;
    min-width: 120px;
    max-width: 100%;
    font-size: 20px;
    font-size: 1.25rem;
    background: #fff;
    text-align: center;
    border: solid 1px #F0F3F5;
    border-radius: 8px;
    box-shadow: 0 3px 8px #F0F3F5;
  }

  .product-balloon-content {
    display: flex;
    justify-content: space-around;

    .sns-share-button {
      background: none;

      &[data-sns-name="facebook"] img {
        filter: invert(28%) sepia(51%) saturate(3475%) hue-rotate(210deg) brightness(98%) contrast(108%);
      }

      &[data-sns-name="linkedin"] img {
        filter: invert(32%) sepia(87%) saturate(808%) hue-rotate(182deg) brightness(90%) contrast(88%);
      }
    }
  }
}

//ランキング
.category-ranking-tab {
  @media #{$sp} {
    .btn-cmn {
      width: auto !important;
      font-size: 14px;
    }
  }
}

.ranking-list-wrap {
  width: 100%;
  border: 1px solid var(--color-gray-pale);
  border-radius: var(--border-radius);

  overflow: hidden;

  .product-card-wrap {
    &+.product-card-wrap {
      border-top: 1px solid var(--color-gray-pale);
    }

    .accordion-header:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-image: var(--color-gradient-primary);
    }
  }

  .progress-wrap {
    flex: 10;

    &+span {
      flex: none;
    }
  }

  .ranking-list-head {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 30px;

    @media #{$sp} {
      flex-direction: column;

      .input-box {
        top: 10px;
        position: absolute;
      }
    }

    .rank-num {
      width: 100%;
      max-width: 60px;
      margin-left: 10px;
    }

    .ranking-list-value {
      flex: 100;
    }

    .product-ttl {
      width: 100%;

      @media #{$pc} {
        max-width: 360px;
      }
    }
  }
}

//関連ニュース
.news-list-wrap {
  >li {
    position: relative;

    .icon-go-page {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
}

//画像トリミング
.trimming {
  width: 100%;
  min-width: 106px;
  max-width: 106px;
  height: 100%;
  min-height: 106px;
  max-height: 106px;
  overflow: hidden;
  border-radius: var(--border-radius);

  img {
    width: 106px;
    height: 106px;
    object-fit: cover;
  }
}

//セミナー
.seminar-list {
  .seminar-info {
    gap: 5px;

    dd {
      align-content: center;
    }

    dt {
      @include gentle-flex();
      width: 80px;
      height: 28px;
      font-weight: bold;
      text-align: center;
      background: #eef1f3;
    }
  }

  .seminar-full {
    background: #e0e0e0;

    .tag-full {
      text-align: center;
      color: var(--color-white);
      background: #959696;
      border-radius: var(--border-radius);
    }
  }

  @media #{$sp} {
    .btn-gradient {
      margin-top: 10px !important;
    }
  }

  .seminar-img {
    min-width: 300px;
    max-width: 600px;
  }
}

//---------------------------

/*検索 */

//---------------------------



.tab {
  &.active {
    background: var(--color-primary-bright);
    border: none;
    border-radius: var(--border-radius);
    color: var(--color-primary);
  }
  .tag-search {
      display: flex;
      align-items: center;
      font-weight: 700;
      height: 44px;
      line-height: 100%;

    }
}


.search-user-wrap {
  @include gentle-flex(center, flex-start);
  padding-bottom: 10px;
  margin-bottom: 10px;
  gap: var(--gutter-s);
  border-bottom: 1px solid var(--color-gray-pale);
  margin: 0 -15px;
  padding: 0 20px 20px;
}

.search-result {
  display: flex;

  .ai-content {
    margin-top: 15px;
  }

  .tab-contents {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 20px;
  }

  @media #{$sp} {
    flex-direction: column;

    .ai-content,
    .tab-contents {
      flex: 0 0 auto;
      max-width: 100%;
      padding-left: 0;
    }
  }
}

.search-result-data {
  @media #{$sp} {
    font-size: 14px;
  }
}

.search-vendor {
  @media #{$sp} {
    .logo-wrap-106 {
      min-width: 80px;
      max-width: 80px;
      min-height: 80px;
      max-height: 80px;
    }
  }
}

//---------------------------

/*検索（AI） */

//---------------------------

.sideframe {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  min-height: 500px;
  max-height: 900px;
  margin-bottom: 1rem;
  overflow-x: auto;
  background: #fff;
  border: 1px solid var(--color-gray-pale);
  border-radius: var(--border-radius);
  resize: both;
  position: relative;
  z-index: 5;

  &-inner {
    height: auto;
    max-height: 600px;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;

    &::after {
      display: block;
      width: 1px;
      height: 1.5em;
      content: "";
    }
  }

  &-bottom {
    padding: 10px;
  }

  .chat-user {
    .chat-content {
      padding: 22px;
      margin-bottom: 24px;
      border-radius: 5px;
      flex: 2;
      position: relative;
      color: white;
      background-color: var(--color-primary);

      &::before {
        content: " ";
        width: 0;
        height: 0;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 0;
        border-color: var(--color-primary) transparent transparent transparent;
        right: -10px;
        top: 18px;
      }

      p {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .chat-master {
    gap: 12px;
    margin-bottom: 12px;
    width: 100%;

    .chat-avatar-img {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .chat-avatar {
        width: 180px;
        @include gentle-flex();
        flex-direction: column;
      }

      .reby-name {
        font-size: 12px;
        color: var(--color-primary);
        font-weight: bold;
        margin-left: 10px;
      }

      .reby-job {
        font-size: 10px;
        color: var(--color-primary);
      }

      .question-product {
        padding-left: 5px;
        width: 90%;

        p {
          font-size: 14px;
        }
      }
    }

    .reby-searching {
      p {
        font-size: 12px;
      }
    }

    .chat-content {
      width: 100%;
      height: auto;
      padding: 22px;
      margin-bottom: 24px;
      border-radius: 5px;
      border: 1px solid var(--color-gray-pale);
      flex: 2;
      position: relative;
      background: #fff;

      &:after,
      &:before {
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        pointer-events: none;
        position: absolute;
        bottom: 100%;
        left: 15%;
      }

      &::after {
        border-color: rgba(255, 255, 255, 0);
        border-top-width: 10px;
        border-bottom-width: 10px;
        border-left-width: 7px;
        border-right-width: 7px;
        margin-left: 10px;
        border-bottom-color: #ffffff;
      }

      &::before {
        border-color: rgba(228, 228, 228, 0);
        border-top-width: 11px;
        border-bottom-width: 11px;
        border-left-width: 8px;
        border-right-width: 8px;
        margin-left: 9px;
        margin-bottom: 1px;
        border-bottom-color: var(--color-gray-pale);
      }

      p {
        font-size: 14px;
      }
    }

    .chat-content-head {
      display: flex;
      font-weight: 600;
      justify-content: space-between;
      margin-bottom: 6px;
    }

    .reby-answer-body {
      white-space: pre-wrap;

      a {
        display: initial;
      }
    }

    .default-link-color {
      color: #338fc7;
      font-weight: bold;
      text-decoration: underline;
      &:hover {
        color: var(--color-primary);
      }
    }

    .tag-parent {
      display: block;
    }

    .tag {
      display: inline-block;
      border-radius: 50px;
      color: #338fc7;
      border: 1px solid #338fc7;
      font-size: 12px;
      font-size: 0.75rem;
      overflow: hidden;
      padding: 4px 8px;
      margin: 0 3px 0 0;
      font-weight: bold;
    }
  }

  .input-text {
    height: 50px;
    background-color: #faecf0;
    border: none;
    margin-bottom: 24px;
  }

  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }

  .btn-chat {
    width: 124px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: white;
    background: #231815;
    border: 1px solid #707070;
    border-radius: 5px;
  }

  .notice {
    line-height: 1.3;
    margin-top: 40px;
    font-size: 12px;
  }

  .chat-survey {
    font-size: 12px;

    p {
      font-size: 14px;
    }

    .helpful-wrap {
      display: flex;
      margin-bottom: 15px;

      .helpful-btn {
        cursor: pointer;

        &:hover {
          background-color: #e51d5f;
        }
      }
    }

    .helpful {
      @include gentle-flex();
      width: 104px;
      height: 30px;
      margin-right: 13px;
      border-radius: 30px;
      border: 1px solid var(--color-gray-pale);

      svg {
        margin-right: 5px;
      }
    }

    .not-helpful {
      @include gentle-flex();
      width: 30px;
      height: 30px;
      border-radius: 30px;
      border: 1px solid var(--color-gray-pale);
    }
  }

  .close-link {
    position: absolute;
    bottom: 0px;
    left: 415px;
    @include gentle-flex();
    height: 45px;
    width: 45px;
    z-index: 9999;
    border-radius: 50%;
    background-color: #703c9b;
  }
}

.search-result-wrap {
  @media only screen and (min-width: 767.98px) and (max-width: 1090px) {
    --item-width: 2;
  }
}

//---------------------------

/*比較 */

//---------------------------
.comparison-product-names {
  align-items: center;
  gap: unset;
  line-height: 1.3;

  >span {
    margin: 0 10px;
    font-size: 26px;

    @media #{$sp} {
      font-size: 14px;
    }
  }
}

.compare-products-name {
  display: inline;
}

.comparison-btn-wrap {
  .js-modal-btn.is-sticky {
    display: block;
  }
}

.list-products-wrap {
  @media #{$sp} {
    li {
      margin-bottom: 10px;
      border: 1px solid var(--color-gray-pale);

      a {
        flex-direction: column;
        align-items: flex-start;

        .product-category {
          margin-left: 70px;
          max-width: 240px;
          max-inline-size: 240px !important;
          text-align: left;

          &.browsing-history {
            margin-left: 35px;
            max-width: 280px;
            max-inline-size: 280px !important;
          }
        }
      }
    }
  }
}

.comparison-wrap {
  width: 100%;
  height: 120px;

  .comparison-inner {
    width: 100%;
    height: 100%;
    @include gentle-flex();
    gap: var(--gutter);
    color: var(--color-gray);
  }
}

.modal-compare-product {
  .grid-list--4 {

    /* iPad Air 縦向き時に適用 */
    @media screen and (min-width: 820px) and (max-width: 1199px) and (orientation: portrait) {
      --item-width: 4;
    }

    @media #{$tablet} {
      --item-width: 4;
    }

    @media #{$sp} {
      grid-template-columns: 47% 47%;
    }

    li {
      width: 185px;
      height: 119px;
      background: var(--color-gray-light);

      /* iPad Air 縦向き時に適用 */
      @media screen and (min-width: 820px) and (max-width: 1199px) and (orientation: portrait) {
        width: 150px;
      }

      @media #{$sp} {
        width: 100%;
        height: 119px;
      }

      &:has(img) {
        background: var(--color-white);
        position: relative;
      }
    }
  }

  .search-form {
    display: flex;
    max-width: none !important;
    height: var(--serch-form-h, 48px);
    background: var(--color-primary-bright);
    border-radius: 25px;
    overflow: hidden;
    position: relative;
  }

  .search-word-wrap {
    position: absolute;
    z-index: map-get($z-index, word-wrap);
    display: block;
  }

  .scroll-y {
    &.search-product-uggest {
      overflow-y: auto;
      overflow-x: hidden;
      height: auto;
      max-height: 200px;

      li:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    &.similar-items {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 200px;
      padding-right: 10px;
    }

    &-inner {
      height: 80%;
    }
  }
}

.comparison-items-wrap {
  display: flex;
  align-items: stretch;
  flex-direction: row;

  @media #{$sp} {
    flex-direction: column;
  }

  li {
    align-items: center;
    display: flex;
    flex: 1;
    height: 120px;
    flex: 1 1 calc(25% - 10px);
    width: calc(100% / 4);
    min-width: calc(25% - 10px);
    position: relative;

    @media #{$sp} {
      width: 100%;
    }

    .icon-plus-circle {
      width: 30px;

    }
  }

  .add-product {
    &.header--2 {
      width: 23%;

      @media #{$sp} {
        width: 100%;

        .comparison-wrap {
          height: 60px;

          span {
            font-size: 14px;
          }
        }
      }
    }

    &.header--3 {
      width: 25%;

      @media #{$sp} {
        width: 100%;

        .comparison-wrap {
          height: 60px;
          font-size: 14px;
        }
      }
    }
  }

  .remove-button {
    position: absolute;
    top: -20px;
    right: 0;
    margin-right: -10px;
    width: 35px;
  }
}

.comparison-products-table {
  border-radius: var(--border-radius);

  border-right: none;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  th,
  td {
    padding: 15px;
    border-right: 1px solid var(--color-gray-pale);
  }

  .comparison-products-body {

    tr,
    td {
      border: 1px solid var(--color-gray-pale);
    }

    td {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &.all-comparison {
    @media #{$sp} {
      white-space: normal;
    }

    thead th {
      @media #{$sp} {
        min-width: 150px;
      }
    }

    .comparison-products-body td:first-child {
      @media #{$sp} {
        min-width: 80px;
        font-size: 12px;
      }
    }
  }

  .comparison-products-body>tr:nth-child(2n + 1) {
    background: var(--color-gray-light);
  }
}

.list-products-wrap {
  .product-name {

    /* iPad Proの横向き（ランドスケープ） */
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
      width: 250px;
    }

    /* iPad Proの縦向き（ポートレート） */
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
      width: 250px;
    }

    /* iPad Air 縦向きのスタイル */
    @media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) and (-webkit-max-device-pixel-ratio: 2) {
      width: 200px;
    }

    /* iPad Air 横向きのスタイル */
    @media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (-webkit-max-device-pixel-ratio: 2) {
      width: 300px;
    }

  }
}


//比較ページ　価格（div）
.comparison-products-wrap {
  ul {
    border: 1px solid var(--color-gray-pale);

    @media #{$sp} {
      min-width: 800px;
      white-space: normal;
    }

    li {
      flex: 1 1 calc(25% - 10px);
      width: 25%;
      min-width: calc(25% - 10px);
      display: flex;
      align-items: stretch;

      @media #{$sp} {
        font-size: 12px;

        p {
          font-size: 12px;

          span {
            font-size: 14px;
          }
        }
      }

      .comparison-products-head {
        display: flex;
        height: 100%;
        flex-shrink: 1;

        @media #{$sp} {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;

          >a {
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            width: 100%;
          }
        }
      }

      .comparison-products-body {
        border-bottom: 1px solid var(--color-gray-pale);

        >div:nth-child(2n + 1) {
          background: var(--color-gray-light);
        }
      }

      &+li {
        border-left: 1px solid var(--color-gray-pale);
      }
    }
  }
}

//比較ページでマスク&会員登録
.float-wrap-register-btn {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  width: 364px;
  --size-content: 364px;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.3s;

  @media #{$sp} {
    width: 100%;
  }

  &.sticky {
    display: block;
    position: fixed;
    top: 30%;
    opacity: 1.0;
    z-index: map-get($z-index, tab);
  }

  li {
    @media #{$sp} {
      width: 300px;
    }
  }
}

.comparison-target {
  margin-top: -60px;
  padding-top: 120px;
}

tbody.mask:not(.price-comparison) {
  tr:nth-child(n + 4) td:nth-child(n + 2) {
    position: relative;

    &:after {
      content: "";
      top: 1px;
      right: 1px;
      position: absolute;
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      backdrop-filter: blur(4px);
    }
  }
}

#satisfaction-compare .mask, #feature-compare .mask {
  tr:nth-child(n + 1) td:nth-child(n + 2) {
    position: relative;

    &:after {
      content: "";
      top: 1px;
      right: 1px;
      position: absolute;
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      backdrop-filter: blur(4px);
    }
  }
}

#price-compare .mask {
  >div {
    position: relative;

    &:after {
      content: "";
      top: 1px;
      right: 1px;
      position: absolute;
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      backdrop-filter: blur(8px);
    }
  }
}

//---------------------------

/*レビュー 投稿*/

//---------------------------

.review-btn-wrap {
  display: flex;
  justify-content: space-between;
}

.stepbar-wrap {
  max-width: var(--main-content-narrow-width);
  margin-top: 100px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;

  @media #{$sp} {
    margin-top: 50px;
  }

  a {
    padding: 0;
  }

  &::before,
  &::after {
    display: table;
    content: " ";
  }

  .stepbar--4::before {
    margin-left: 12.5%;
    width: 75%;
  }

  .stepbar {
    display: flex;
    line-height: 12px;
    margin-bottom: 18px;
    position: relative;

    &::before {
      background-color: var(--color-gray-bright);
      content: " ";
      display: block;
      height: 4px;
      position: absolute;
      top: -16px;
      transform: translate(0%, -50%);
      z-index: 1;
    }
  }

  .stepbar-step {
    color: var(--color-gray);
    display: flex;
    justify-content: center;
    width: 25%;
    font-size: 12px;
    font-weight: 600;
    position: relative;

    &::after {
      border: 6px solid var(--color-gray-bright);
      border-radius: 100%;
      content: " ";

      width: 12px;
      height: 12px;
      left: 50%;
      position: absolute;
      top: -16px;
      transform: translate(-50%, -50%);

      z-index: 3;
    }

    &.step-current {
      color: var(--color-black);

      &:before {
        content: " ";
        width: 30px;
        height: 30px;
        left: 50%;
        position: absolute;
        top: -16px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        border: 15px solid var(--color-gray-bright);
        z-index: 2;
      }

      &:after {
        width: 20px;
        height: 20px;
        border-color: var(--color-primary);
        border-width: 10px;
      }
    }
  }

  .small-step-wrap {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    font-size: 1px;
    font-weight: 600;
    width: 20%;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    .small-step {
      &::after {
        border: 4px solid var(--color-gray-bright);
        border-radius: 100%;
        content: " ";

        width: 10px;
        height: 10px;
        left: 50%;

        z-index: 3;
      }

      &.step-current {
        position: relative;

        &:before {
          content: " ";
          width: 20px;
          height: 20px;
          left: 50%;
          position: absolute;
          top: 5px;
          transform: translate(-50%, -50%);
          border-radius: 100%;
          border: 10px solid var(--color-gray-bright);
          z-index: 2;
        }

        &:after {
          width: 10px;
          height: 10px;
          border-color: var(--color-primary);
          border-width: 5px;
          position: absolute;
          top: 5px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.step-wrap {
  .index-step {
    display: flex;
    flex-direction: column;
    grid-gap: 0.625rem;
    gap: 0.625rem;
    grid-gap: var(--gutter-ss);
    gap: var(--gutter-ss);
    line-height: 1;
  }

  .review-circle {
    height: 78px;
    @include gentle-flex;
    gap: var(--gutter);
    flex: 0 1 78px;
    border-radius: 50%;
    font-size: 32px;
    font-weight: bold;

    @media #{$sp} {
      width: 100%;
      min-width: 30px;
      max-width: 30px;
      height: 100%;
      min-height: 30px;
      max-height: 30px;
      font-size: 14px;
    }

    &.min {
      height: 58px;
      flex: 0 1 58px;
      font-size: 20px;

      @media #{$sp} {
        width: 100%;
        min-width: 30px;
        max-width: 30px;
        height: 100%;
        min-height: 30px;
        max-height: 30px;
        font-size: 14px;
      }
    }
  }

  .review-oblong {
    @include gentle-flex;
    gap: var(--gutter);
    width: 180px;
    height: 58px;
    font-size: 20px;
    border-radius: 40px;

    @media #{$sp} {
      width: auto;
      height: 30px;
      padding: 0 10px;
      font-size: 12px;
      line-height: 1;
    }
  }

  .review-rectangle {
    @include gentle-flex;
    gap: var(--gutter);
    width: 100%;
    flex: 1;
    height: 58px;
    font-size: 16px;
    padding: 5px;

    @media #{$sp} {
      width: auto;
      height: auto;
      font-size: 14px;
      align-items: stretch;
      list-style: 1.2;
    }
  }

  .step-question {
    textarea::placeholder {
      white-space: pre-wrap;
      /* 改行と空白を保持する */
    }

    .tag-category {
      position: relative;
      padding-right: 30px;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        @include svgIcon("facebook");
        width: 8px;
        height: 17px;
      }
    }
  }

  .step-question-confirm-btn {
    width: 100%;
    max-width: 600px;
    @include align-left;
  }

  .step-question-confirm {
    &.icon-check {
      dd {
        position: relative;
        padding-left: 30px;
      }
    }
  }
}

.autocomplete-wrapper {
  position: relative;
}

.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid var(--color-gray-pale);
  border-radius: var(--border-radius);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.autocomplete-suggestion {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  .suggestion-content {
    display: flex;
    align-items: center;
  }
}

.autocomplete-suggestion__image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  object-fit: contain;
  flex-shrink: 0;
}

.suggestion-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.autocomplete-suggestion.selected {
  background-color: #f0f0f0;
}

.hide {
  display: none;
}

//---------------------------

/*リードフォーム*/

//---------------------------

.leadform-sidebar {
  height: fit-content;

  li:has(.checkbox-button:checked, .radio-button:checked) {
    color: var(--color-white);
    background: var(--color-primary);
  }
}

/*---------------------------*/

/*用語集*/

/*---------------------------*/
.category-index-wrap {
  .top-category-wrap {
    display: flex;
    width: 100%;
    border: 1px solid var(--color-gray-pale);

    .top-category-name {
      width: 16%;
      background-color: var(--color-primary);
    }

    .middle-category-wrap {
      width: 84%;
      display: flex;
      flex-direction: column;

      .middle-category {
        display: flex;
        position: relative;

        width: 100%;
        flex-wrap: nowrap;
        flex-grow: 1;
        border-bottom: 1px solid var(--color-gray-pale);

        &:last-child {
          border-bottom: none;
        }

        .middle-category-name {
          width: 21%;
          background: var(--color-primary-bright);
        }

        .bottom-category-wrap {
          width: 84%;
          display: flex;

          justify-content: flex-start;
          flex-wrap: wrap;

          .bottom-category {
            display: flex;

            align-items: center;

            &:not(:last-child):after {
              content: "|";
              color: var(--color-gray-pale);
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
}

.glossary-wrap {
  .prose {
    a:not(.tag-category) {
      display: inline;
      color: var(--color-blue);
      text-decoration: underline;
      overflow-wrap: break-word;
      word-break: break-word;
    }
  }
}

/*---------------------------*/

/*お問合せ */

/*---------------------------*/
.faq-wrap {
  .anchors li a:before {
    content: "●";
    font-weight: 700;
    margin-right: 5px;
    font-size: 12px;
    font-size: 10px;
    color: var(--color-gray);
  }

  .accordion {
    .question {
      cursor: pointer;
      margin-bottom: 1em;
      border: 1px solid var(--color-gray-pale);
      border-radius: var(--border-radius);
      padding: 10px;
      position: relative;

      @media #{$sp} {
        padding-right: 15px;
        font-size: 14px;
      }

      &:after {
        content: "+";
        position: absolute;
        right: 10px;
        transition: transform 0.3s ease;
      }

      &.open {
        background-color: var(--color-primary-bright);
        border-color: var(--color-primary-bright);

        &:after {
          content: "-";
          right: 10px;
          transform: rotate(360deg);
        }
      }
    }

    .answer {
      display: none;
      margin-bottom: 15px;
      padding-left: 10px;
    }
  }

  .faq-confirm {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    dt,
    dd {
      margin: 0;
      padding: 10px;
      border-bottom: 1px solid var(--color-gray-pale);
    }

    dt {
      grid-column: span 1;
    }

    dd {
      grid-column: span 2;
    }
  }
}

/*---------------------------*/

/*ベンダーサイト */

/*---------------------------*/

.corporate-data {
  display: grid;
  grid-template-columns: repeat(3, calc((100% / 3) - 7.5px));

  grid-auto-rows: 1fr;
  gap: 15px;
  align-items: stretch;
  gap: 15px;

  @media #{$sp} {
    display: flex;
    flex-direction: column;
  }

  li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .index {
      letter-spacing: 0.8px;
    }

    .treat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 15px;
    }
  }
}

.one-star-style {
  .vendor-bar-container {
    img {
      width: 20px;
    }
    .vendor-bar-label {
      word-wrap: break-word;
      font-weight: 700;
    }
  }
}

.vendor-bar-wrap {
  width: 100%;
  max-width: 330px;

  @media #{$sp} {
    width: 100%;
    min-width: 300px;
    max-width: 300px;
  }

  .vendor-bar-container {
    height: 30px;

    .vendor-bar-label {
      width: 60px;
    }

    .vendor-bar {
      flex-grow: 1;
      width: 100%;

      max-width: 130px;
    }

    .bar {
      height: 10px;
      border-radius: 30px;
      background: var(--color-primary);
    }

    .vendor-bar-value {
      width: 100%;
      max-width: 50px;
      text-align: right;
    }
  }
}

.vendor-chart-wrap {
  flex-grow: 1;
  width: 100%;

  .vendor-chart-container {
    width: 400px;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
  }
}

//---------------------------

/* マイページ */

//---------------------------
.user-mypage {
  .side {
    width: 100%;
    max-width: 300px;
    text-align: center;

    @media #{$sp} {
      margin: 0 auto;
    }

    .user-avatar-wrap {
      cursor: pointer;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      z-index: 0;
      margin: 0 auto;

      @media #{$sp} {
        width: 100px;
        height: 100px;
      }

      .user-avatar-label {
        @include gentle-flex();
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0;

        &:before {
          position: absolute;
          @include svgIcon("icon-edit");
          width: 24px;
          height: 24px;
          z-index: 2;
        }
      }

      .avatar-preview-show {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--color-white);
        z-index: 2;
      }

      .avatar-preview-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom right, #3583fe, #f71f84);
      }
    }
  }

  .content {
    flex-grow: 1;
    width: 100%;
    max-width: 640px;
  }

  .checkbox-list {
    li:has(.checkbox-button:checked, .radio-button:checked) {
      color: var(--color-white);
      background: var(--color-primary);
    }
  }

  .paypay-notice {
    dd {
      padding-left: 15px;
      margin-bottom: 15px;
      position: relative;

      &:before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        background: #f00227;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

// TODO: オートコンプリート系はどっか一つにまとめておきたい
.autocomplete-items {
  max-height: 200px;
  overflow-y: auto;
}

//電話番号
.telephone-form-global {
  padding-left: 90px !important;
}

/*---------------------------*/

/*カテレポ */

/*---------------------------*/

.category-report {
  .grid-wrap {
    .category-grid-table {
      width: 95% !important;
    }
  }

  .category-grid-footer {
    margin-top: 50px !important;

    a {
      display: inline-block;
      width: auto;
      height: auto;
      background: transparent;
      padding: 0;
    }
  }

  .segment {
    &.red {
      background-color: #ea3380;
    }

    &.yellow {
      background-color: #ffbb44;
    }

    &.blue {
      background-color: #336fc0;
    }

    &.purple {
      background-color: #7769bb;
    }
  }

  .graph-wrap-width {
    width: calc(100% - 190px);
  }

  .logo-ttl {
    width: 100%;
    min-width: 190px;
    max-width: 190px;
  }

  .segment-color-config {
    .segment {
      width: 26px;
      height: 10px;
      display: block;
      border-radius: 30px;
      margin-right: 5px;
    }
  }

  .graph-labels {
    margin-left: auto;
    display: flex;
    margin-top: 5px;

    &.labels-index {
      justify-content: center;

      .left {
        margin-right: 15px;
      }

      .right {
        margin-right: 15px;
      }
    }

    &.labels-number {
      justify-content: space-between;
    }
  }

  .category-report-table {
    max-width: var(--wrapper-width);
    position: relative;

    thead {
      height: 30px;
      font-size: 12px;

      th {
        min-width: 100px;
        padding: 15px 0;
        text-align: center;
        line-height: 1;

        &:first-child {
          left: 0;
          position: sticky;
          z-index: 1;
          background-color: var(--color-white);

          @media #{$sp} {
            min-width: 80px;
          }
        }
      }
    }

    tbody {
      font-weight: bold;

      td {
        vertical-align: middle;
        min-width: 100px;
        height: 42px;
        text-align: center;
        border: 1px solid var(--color-white);

        &:first-child {
          min-width: 190px;
          max-width: 190px;
          padding-right: 10px;
          table-layout: fixed;
          left: 0;
          position: sticky;
          z-index: 1;
          font-size: 14px;
          text-align: left;
          background-color: var(--color-white);
        }

        &:hover {
          position: relative;
        }
      }
    }

    tr:first-child td {
      border-top: 1px solid var(--color-gray-pale);
    }

    td {
      padding: 15px 0;
      border-bottom: 1px solid var(--color-gray-pale);

      &:first-child {
        background: var(--color-white);
      }

      .badge {
        margin: 0 auto;
      }
    }
  }

  .graph-container {
    width: 100%;

    .graph-inner {
      margin-left: auto;
    }

    .graph-bars {
      display: flex;

      align-items: center;
      position: relative;
      height: 30px;

      &.two-line {
        justify-content: space-around;
      }
    }

    .graph-bar {
      width: 100%;
      height: 10px;

      .progress {
        height: 10px;
        background: var(--color-gray-light);

        .progress-meter {
          border: 1px solid var(--color-white);
          border-radius: 0;

          .feature-list-value &,
          .ranking-list-value & {
            height: 10px;
          }

          &-reverse-overlay {
            background-color: var(--color-white);
            bottom: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
          }
        }
      }

      &.right {
        margin-left: 15px;
      }

      &.left {
        margin-right: 15px;

        .progress-meter {
          transform: scale(-1, 1);

          .tooltip {
            transform: scale(-1, 1);
          }
        }
      }
    }

    .center-line {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 2px;
      transform: translateX(-50%);
      background-color: var(--color-gray-pale);
      z-index: 2;
    }

    .label {
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .bar-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;

    .bar {
      display: flex;
      min-height: 20px;
      margin-left: auto;

      .segment {
        height: 10px;
        border-radius: 30px;
        position: relative;
        margin-right: 5px;
        &[data-percentage="0"] {
          display: none;
        }
        &:last-child {
          margin-right: 0;
        }

        .tooltip {
          @include tooltip;
        }

        &:hover .tooltip {
          display: block;
        }
      }
      .segment + .segment[data-percentage="0"] {
        display: block;
        margin-left: -5px;
      }

      &.graph-operation-period .segment {
        margin-bottom: 5px;

        +.segment {
          margin-left: 0;
          margin-bottom: 5px;
        }
      }
    }

    .label {
      width: 100px;
      text-align: right;
    }
  }

  .segment-color-config {
    @media #{$sp} {
      >p {
        font-size: 12px;
      }
    }
  }
}

//製品を購入
.buy-category {
  .link {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    &:after {
      @include svgIcon("icon-external-link-blue");
      width: 16px;
      height: 16px;
    }
  }
}


/*---------------------------*/

/*キャンペーン一覧 */

/*---------------------------*/
.campaign-banner-wrap {
  &.past {
    color: var(--color-gray);

    .campaign-banner {
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include gentle-flex;
        content: "終了しました";
        color: var(--color-white);
        font-weight: 700;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

/*---------------------------*/

/*チャリティ*/
/*Todo//ベンダーサイトのとこ？未実装箇所

/*---------------------------*/
.charity-nominated-user-tag-icon-to-overlay {
  position: relative;
  margin-top: -100px;
  height: 100px;
  display: flex;
  justify-content: center;

  .charity-nominated-user-tag-icon {
    &::before {
      width: 60px;
      height: 60px;
      @include svgIcon("charity_participation_review");
    }
  }
}

.charity-tooltip {
  position: absolute;
  bottom: -5px;
  right: 30px;
  cursor: pointer;
  display: inline-block;

  p {
    margin: 0;
    padding: 0;
  }
}

.description {
  display: none;
  position: absolute;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.6em;
  color: #fff;
  border-radius: 5px;
  background: var(--color-blue-bright);
  width: 200px;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border: 7px solid transparent;
    border-top: 7px solid var(--color-blue-bright);
    margin-left: -7px;
  }
}

.charity-tooltip:hover .description {
  display: inline-block;
  top: -70px;
  left: -30px;
}

.charity-participation-icon {
  &::before {
    width: 30px;
    height: 30px;
    @include svgIcon("charity_participation_review");
    // TODO:: ベンダーの場合はこちらのバッジを表示
    // background-image: url("../../../images/front/svg/charity_participation_vendor.svg");
  }
}

//---------------------------

/* 右カラム */

//---------------------------

.category-sidebar-filter {
  font-size: 14px;

  li {
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;

    &:has(.checkbox-button:checked, .radio-button:checked) {
      background: var(--color-primary-bright);
    }

    &.category-section {
      padding: 0;

      &:has(.checkbox-button:checked, .radio-button:checked) {
        &:has(.checkbox-button:checked, .radio-button:checked) {
          background: transparent;
        }
      }
    }

    dl {
      width: 100%;

      span {
        padding-left: 10px;
      }

      dt {
        display: flex;
        align-items: center;
        padding: 10px;

        &:has(.checkbox-button:checked, .radio-button:checked) {
          background: var(--color-primary-bright);
        }
      }

      dd {
        display: flex;
        align-items: center;
        padding: 10px 10px 10px 30px;
        text-indent: -10px;

        &:has(.checkbox-button:checked, .radio-button:checked) {
          background: var(--color-primary-bright);
        }

        .checkbox-button {
          left: 10px;
        }

        .input-box {
          margin-right: 15px;
        }
      }
    }
  }
}

//所属カテゴリー
.sidebar-list {
  li {
    border-bottom: 1px solid var(--color-gray-light);

    &:first-child {
      border-top: 1px solid var(--color-gray-light);
    }

    a {
      padding: 10px 0;
    }
  }
}

//比較
.compare-list-wrap {
  .compare-list {
    margin-bottom: 15px;

    li {
      position: relative;
    }

    &-img {
      @include gentle-flex;
      width: 50%;
      gap: var(--gutter);
    }

    &-txt {
      width: 50%;
      margin-top: 10px;
      text-align: center;
    }

    &-vs {
      font-weight: 600;
      height: 36px;
      left: 50%;
      position: absolute;
      text-align: center;
      top: 61px;
      transform: translate(-50%, -50%);
      width: 36px;
    }
  }

  .btn-gradient,
  .btn-black {
    width: 134px;
    max-inline-size: none;
  }
}

//カテゴリーのスクロール
.sidebar {
  // 製品一覧の高さと揃えるためのマージン
  font-size: 14px;

  .side-nav {
    dt {
      padding-bottom: 15px;
      border-bottom: 1px solid var(--color-gray-pale);
    }

    &.text-indent {
      dt {
        padding-bottom: 0;
        border: none;
        margin: 10px 0;
      }

      dd {
        padding-left: 1em;

        &[data-indent-level="0"] {
          padding-left: 0;
        }

        &[data-indent-level="1"] {
          padding-left: 1em;
        }

        &[data-indent-level="2"] {
          padding-left: 2em;
        }

      }
    }

    &-scroll {
      height: auto;
      max-height: 235px;
      width: 98%;
      overflow: auto;
      padding-right: 12px;

      a {
        color: var(--color-black);
      }
    }
  }
}

//イベント
.event-sidebar-filter {
  input[type="text"] {
    z-index: 1;
    padding-left: 50px;
  }

  .icon-calendar {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }
}

.event-list {
  li {
    @media #{$sp} {
      img {
        max-width: 100px;
      }
    }

    .event-list-date {
      font-size: 24px;
      font-weight: bold;

      @media #{$sp} {
        font-size: 16px;
        ;
      }

      .week,
      .time {
        font-size: 18px;

        @media #{$sp} {
          font-size: 14px;
        }
      }

      .time+.time {
        &:before {
          content: "〜";
          display: inline-block;
          margin: 0 10px;
        }
      }
    }

    .block:has(.diff-date) {
      .event-list-date {
        font-size: 16px;
        font-weight: bold;

        @media #{$sp} {
          font-size: 14px;
        }

        &.diff-date:before {
          content: "〜";
          display: inline-block;
          margin: 0 5px;
        }

        .week,
        .time {
          font-size: 14px;

          @media #{$sp} {
            font-size: 12px;
          }
        }
      }
    }
  }
}

//ad
#cta-container {
  position: fixed;
  pointer-events: auto;
  width: 100%;
  max-width: 320px;
  bottom: 120px;
  right: 30px;
  @include gentle-flex();
  z-index: map-get($z-index, ad);

  @media #{$sp} {
    margin-right: 1rem;
    margin-bottom: 3rem;
  }
}

.cta_content {
  width: 100%;
  max-width: 320px;
  overflow: hidden;

  >a {
    background: transparent;
    padding: 0;
  }

  .cta_image {
    @include gentle-flex();
    border: 1px solid var(--color-gray-pale);
    background: #fff;
    height: 150px;
    position: relative;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    @media #{$sp} {
      display: none;
    }
  }

  .cta_text {
    height: 100px;
    overflow: hidden;
    padding: 10px;
    color: var(--color-white);
    text-align: center;
    @include gentle-flex();
    font-size: 16px;
    font-family: bold;
  }

  .cta_close_btn {
    position: absolute;
    display: inline-block;
    top: -10px;
    right: -30px;
    z-index: map-get($z-index, ad);
  }
}

.sidebar.ad-block {
  min-width: 300px;
  min-height: 250px;
  max-height: 250px;
  text-align: center;
}

//---------------------------

/* それ以外 */

//---------------------------

//----------------------------------------------
//フラッシュメッセージ
#toast-container {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: map-get($z-index, toast);
  padding: var(--gutter-s);
  border: 1px solid var(--color-gray-pale);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  cursor: pointer;
  font-weight: bold;
  background: var(--color-white);

  .toast {
    display: flex;
    align-items: center;

    &.toast-success:before,
    &.toast-error:before {
      @include svgIcon("icon-success");
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    &.toast-error:before {
      @include svgIcon("icon-err");
    }
  }
}

.chart-tooltip {
  position: absolute;
  width: 20%;
  min-width: 250px;
  color: var(--color-black);
  background: var(--color-white);
  border: 1px solid var(--color-gray-pale);
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.5);
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 0.1s ease;
  pointer-events: none;

  .tooltip-inner {
    margin: 0;
  }

  .tooltip-content {
    display: flex;
    justify-content: start;
    padding: 10px 5px;
    font-size: 14px;

    .label-dot {
      display: block;
      width: 10px;
      height: 10px;
      margin: 5px;
      border-width: 10px;
      border-radius: 50px;
      overflow: hidden;
    }

    .label-wrapper {
      width: 100%;

      .label-inner {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

// GAM
.ad-block iframe {
  width: 100%;
  border: none;
  margin: 0 auto;
  @media #{$pc} {
    height: 100%;
  }
  @media #{$sp} {
    height: auto;
  }


  .product-banner & {
    margin: 0;
    display: block;
    margin-left: auto;
    max-width: 930px;
    height: revert-layer;
  }

  a {
    display: block;
  }

  .img_ad {
    width: 100%;
    height: 100%;
  }
}

.ad-block {
  &[data-type="banner"] {
    min-width: 728px;
    min-height: 90px;
    margin: 24px auto;
    @include gentle-flex();
    width: 728px;
    height: 100%;
    position: relative;

    @media #{$sp} {
      width: 320px;
      min-width: 320px;
      max-width: 320px;
      height: auto;
    }

    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    iframe {
      max-width: 728px;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }

      @media #{$sp} {
        width: 320px;
        height: auto;
        max-width: 320px;
      }
    }
  }

  &[data-type="banner-sp"] {
    min-width: 320px;
    min-height: 50px;
    margin: 20px auto;
  }

  &[data-type="rectangle"] {
    margin: 16px auto;
    min-height: 250px;
    min-width: 300px;
    height: 100%;
    max-height: 300px;
    max-height: 250px;
    @include gentle-flex();

    >div {
      height: 100%;
    }

    iframe {
      min-height: 250px;
    }
  }

  &[data-type="cover"] {
    min-width: 930px;
    min-height: 180px;
    padding-top: 20px;

    .product-banner & {
      display: contents;
      min-width: 100%;
      min-height: auto;
      padding-top: 0;

      >div {
        display: contents;
      }
    }
  }
}

// fadein
.fade-in {
  animation: categorySearchFadeIn 0.4s forwards;
}

@keyframes categorySearchFadeIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
