//---------------------------

/* RESET */

//---------------------------

/***
    The new CSS reset - version 1.9 (last updated 19.6.2023)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";

*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

p {
  word-break: break-all;
}

a,
button {
  cursor: revert;
  word-break: break-all;
}

a:focus,
*:focus {
  outline: none;
}

ol,
ul,
menu {
  list-style: none;
}

img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

table {
  border-collapse: collapse;
}

input,
textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

:where(pre) {
  all: revert;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

//---------------------------

/* VARIABLES */

//---------------------------
//Breakpoints
$sp: "(max-width: 767.98px)";
$sp-tablet: "(max-width: 959.98px)";
$tablet: "(min-width: 480.99px) and (max-width: 959.98px)";
$pc: "(min-width: 960.99px), print";

//Fontstacks
$main-fonts: "Noto Sans JP",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴ Pro W3",
"Meiryo",
Arial,
sans-serif !important;

//Basic stylings
:root {
  --color-white: #ffffff;
  --color-white-off: #f8fcff;
  --color-black: #231815;
  --color-primary: #e50060;
  --color-primary-light: #ffccdd;
  --color-primary-bright: #faecf0;
  --color-red: #ff0a0a;
  --color-blue: #004bb1;
  --color-blue-light: #ccefff;
  --color-blue-bright: #ddf4ff;
  --color-blue-pale: #ddebff;
  --color-cyan-blue: #36bcd9;
  --color-light-blue: #4680e0;
  --color-gray: #9fa0a0;
  --color-gray-slategray: #D9D9D9;
  --color-gray-dark: #e4e4e4;
  --color-gray-light: #f2f5f7;
  --color-gray-bright: #e5e5e5;
  --color-gray-pale: #f0f3f5;
  --color-gray-bluette: #707f89;
  --color-yellow: #ffbb44;
  --color-orange: #ea830c;
  --color-orange-light: #ffe0ab;
  --color-orange-pale: #fff1d8;
  --color-turquoise: #11aa88;
  --color-turquoise-light: #ccffe6;
  --color-turquoise-pale: #e3fdf7;
  --color-green: #11aa3a;
  --color-green-light: #f1fde3;
  --color-purple: #7769bb;
  --color-purple-light: #deccff;
  --color-purple-pale: #f1e9fd;
  --color-rt: #ff492c;
  --color-rb: #5a39a2;
  --color-lt: #004bb1;
  --color-lb: #4bb7d3;

  --color-gradient-primary: linear-gradient(90deg, var(--color-primary) 0%, var(--color-orange) 100%);
  --color-gradient-second: linear-gradient(90deg, var(--color-primary-light) 0%, var(--color-orange-light) 100%);
  --color-gradient-third: linear-gradient(90deg, var(--color-primary-light) 0%, var(--color-blue-light) 100%);
  --color-gradient-fourth: linear-gradient(90deg, var(--color-turquoise-light) 0%, var(--color-blue-light) 100%);
  --color-gradient-fifth: linear-gradient(90deg, var(--color-primary-light) 0%, var(--color-purple-light) 100%);
  --color-gradient-sixth: linear-gradient(115deg, #c5d6fe 0%, #acc5fb 25%, #dfeeda 100%);
  --color-gradient-graph: linear-gradient(to right,
      #2690ce,
      #1e7dca,
      #2f69c1,
      #4653b4,
      #5a39a2,
      #942a9b,
      #c10c89,
      #e20070,
      #f71d51,
      #ff492c);
  --color-gradient-value: linear-gradient(90deg,
      rgba(54, 188, 217, 1) 0%,
      rgba(54, 188, 217, 1) 20%,
      rgba(85, 68, 170, 1) 20%,
      rgba(0, 75, 177, 1) 40%,
      rgba(85, 68, 170, 1) 40%,
      rgba(85, 68, 170, 1) 60%,
      rgba(229, 0, 96, 1) 60%,
      rgba(229, 0, 96, 1) 80%,
      rgba(255, 187, 68, 1) 80%,
      rgba(255, 187, 68, 1) 100%);
  --color-gradient-banner: linear-gradient(90deg, #faecf0 0%, #ffe0ab 100%);
  --box-shadow: 0 3px 8px rgba(240, 243, 245, 1);
  --main-content-width: 1280px;
  --main-content-narrow-width: 1020px;
  --main-content-narrowest-width: 630px;
  --wrapper-width: 890px;
  --side-width: 300px;
  --side-narrow-width: 295px;
  --gutter: 1.875rem;
  --gutter-l: 1.5rem;
  --gutter-m: 1.25rem;
  --gutter-s: 1rem;
  --gutter-ss: 0.625rem;
  --unit: 30px;
  --size: 1;
  --size: 2;
  --size: 3;
  --size: 4;
  --header-news-h: 46px;
  --header-h: 64px;
  --category-grid-w: 1200px;
  --category-grid-h: 500px;
  --movie-w: 100%;
  --border-radius: 5px;

  @media #{$sp} {
    --header-h: 40px;
    --unit: 15px;
    --main-content-width: 100%;
    --gutter: 1.2rem;
    --gutter-m: 0.938rem;
    --gutter-s: 0.625rem;
    --gutter-ss: 0.3rem;
  }
}

$z-index: (header: 100,
  sp-menu: 1000,
  sp-menu-btn: 9999,
  tab: 80,
  word-wrap: 70,
  modal: 999,
  grid-popup: 999,
  toast: 900,
  ad: 800,
  fix-fotter: 90,
  footer: 50);

// Element Selector Variables
$button-selectors: "input[type=submit]",
"input[type=reset],input[type=button]",
"button";
$text-inputs-selectors: ("[type='color']",
  "[type='date']",
  "[type='datetime']",
  "[type='datetime-local']",
  "[type='email']",
  "[type='month']",
  "[type='number']",
  "[type='password']",
  "[type='search']",
  "[type='tel']",
  "[type='text']",
  "[type='time']",
  "[type='url']",
  "[type='week']",
  "input:not([type])",
  "textarea"
);
//---------------------------

/* BASE */

//---------------------------
html,
body {
  height: 100%;
  min-height: 100vh;
  max-width: 100vw;
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-size: 1rem;
  background: var(--color-white);
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
}

body {
  color: var(--color-black);
  font-family: $main-fonts;
  line-height: 1.6;
  overflow-wrap: break-word;
}

.main-content {
  width: 100%;
  min-height: 766px;
  position: relative;
  background: var(--color-white);

  @media #{$sp} {
    min-height: auto;
  }
}

.container {
  width: 100%;
  max-width: var(--main-content-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  position: relative;

  &.w-narrow-inner {
    max-width: var(--main-content-narrow-width);
    margin-top: 80px;

    @media #{$sp} {
      margin-top: 40px;
    }
  }

  &.w-narrowest-inner {
    max-width: var(--main-content-narrowest-width);
    margin-top: 80px;

    @media #{$sp} {
      margin-top: 40px;
    }
  }
}

//---------------------------

/* Basic stylings */

//---------------------------
img,
picture {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

svg[class^="icon-"],
svg[class*=" icon-"] {
  display: inline-block;
  fill: currentColor;
  height: 1rem;
  overflow: visible;
  width: 1rem;
}

// Background SVG Filter
// use: @extend %svg-fil-primary;
%svg-fil-primary {
  filter: invert(17%) sepia(94%) saturate(7061%) hue-rotate(328deg) brightness(88%) contrast(104%);
}

.noto-emoji {
  font-family: "Noto Color Emoji", sans-serif;
}

//---------------------------
/* Layout */
//---------------------------

.columns {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gutter);

  @media #{$sp-tablet} {
    flex-direction: column;
  }

  .sidebar {
    flex-basis: var(--side-width);
    flex-grow: 1;

    @media #{$sp} {
      width: 99%;
    }

    .narrow-width {
      flex-basis: var(--side-narrow-width);
    }

    .report-btn {
      padding: 5px 10px;
    }
  }

  .wrapper {
    flex-basis: 0;
    flex-grow: 999;
    max-width: var(--wrapper-width);
    @media only screen and (min-width: 701px) and (max-width: 1150px) {
      max-width: 100%;
    }

    @media #{$sp-tablet} {
      max-width: 100%;
      flex-grow: 1;
    }
  }
}

section,
.region {
  --content-gutter: clamp(3.75rem, 0.994rem + 2.84vw, 3.125rem);
  margin-top: var(--content-gutter);

  &+* {
    --content-gutter: clamp(3.75rem, 0.994rem + 2.84vw, 3.125rem);
    margin-top: var(--content-gutter);
  }
}

//メニュー固定
.sticky {
  position: sticky;

  &.width-max {
    top: var(--header-h);

    &.bor-none {
      border-bottom: none;
    }
  }

  .sidebar & {
    top: calc(var(--header-h) + 8ch);
  }
}

.divider {
  height: 1px;
  --divide-space: clamp(0.625rem, 0.398rem + 1.14vw, 1.25rem);
  margin-top: var(--divide-space);
  margin-bottom: var(--divide-space);
  background-color: var(--color-gray-pale);
}

@media #{$sp-tablet} {
  .pc-only {
    display: none !important;
  }
}

@media #{$pc} {
  .sp-tablet-only {
    display: none !important;
  }
}

@media #{$sp} {
  .pc-only {
    display: none !important;
  }
}

@media #{$pc} {
  .sp-only {
    display: none !important;
  }
}

//---------------------------

/* FORMS AND INPUTS */

//---------------------------
button,
html input[type="button"],
input[type="reset"],
input[type="submit"],
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"],
select,
textarea {
  display: block;
  width: 100%;
  font-weight: 500;
  line-height: 1;
  vertical-align: middle;
  transition: all 0.15s ease-out;
  word-break: normal;
  background-clip: padding-box;

  &[disabled] {
    cursor: not-allowed;
    opacity: 1;
  }
}

::placeholder {
  font-size: 14px;
  color: var(--color-gray);
}

#{$text-inputs-selectors} {
  letter-spacing: 0.5px;
  display: block;
  line-height: 1.4;
  margin: 0;
}

//---------------------------

/* Typography */
//---------------------------

h1 {
  --font-size24-38: clamp(1.5rem, 1.182rem + 1.59vw, 2.375rem);
  font-size: var(--font-size24-38);

  &:not(.header-logo):not(.not-flow)+* {
    --flow-space-lx: clamp(1rem, 0.455rem + 2.73vw, 2.5rem);
    margin-top: var(--flow-space-lx, 1em);
  }
}

h2,
.font-size-32 {
  --font-size22-32: clamp(1.375rem, 1.148rem + 1.14vw, 2rem);
  font-size: var(--font-size22-32);

  &:not(.not-flow)+* {
    --flow-space-l: clamp(1rem, 0.455rem + 2.73vw, 2.5rem);
    margin-top: var(--flow-space-l, 1em);
  }
}

h3,
.font-size-28 {
  --font-size20-28: clamp(1.25rem, 1.068rem + 0.91vw, 1.75rem);
  font-size: var(--font-size20-28);
  line-height: 1.4em;

  &:not(.not-flow)+* {
    --flow-space-m: clamp(0.625rem, 0.398rem + 1.14vw, 1.25rem);
    margin-top: var(--flow-space-m, 1em);
  }
}

h4,
.font-size-24 {
  --font-size18-24: clamp(1.125rem, 0.989rem + 0.68vw, 1.5rem);
  font-size: var(--font-size18-24);
  line-height: 1.4em;
}

h5,
.font-size-20 {
  --font-size16-20: clamp(1rem, 0.909rem + 0.45vw, 1.25rem);
  font-size: var(--font-size16-20);

  &:not(.not-flow)+* {
    --flow-space-m: clamp(0.625rem, 0.398rem + 1.14vw, 1.25rem);
    margin-block-start: var(--flow-space-m);
  }
}

h6,
.font-size-18 {
  --font-size16-18: clamp(1rem, 0.955rem + 0.23vw, 1.125rem);
  font-size: var(--font-size16-18);
  line-height: 1.4em;

  &:not(.not-flow)+* {
    --flow-space-m: clamp(0.625rem, 0.398rem + 1.14vw, 1.25rem);
    margin-block-start: var(--flow-space-m);
  }
}

:is(h1, h2, h3, h4, h5) {
  display: flex;
  font-weight: 700;
  gap: 1ch;
  flex-flow: wrap;

  @media #{$sp} {
    gap: 0;
  }
}

.font-size-32,
.font-size-28,
.font-size-24,
.font-size-20 {
  font-weight: bold;

  @media #{$sp} {
    line-height: 1.4em;
  }
}

p {
  --font-size14-16: clamp(0.875rem, 0.83rem + 0.23vw, 1rem);
  font-size: var(--font-size14-16);
  line-height: 1.75em;
}

strong,
.bold {
  font-weight: bold;
}

small,
.small {
  font-size: 0.75em;
  font-weight: normal;
}

//---------------------------

/* 要素を積み重ねる時のマージン */

//---------------------------

.flow-l>*:not(.not-flow)+* {
  --flow-space-xl: clamp(1.25rem, 1.023rem + 1.14vw, 1.875rem);
  margin-top: var(--flow-space-xl);
}

.flow-l :is(h2) {
  --flow-space-xxl: clamp(1.25rem, 0.114rem + 5.68vw, 4.375rem);
  margin-top: var(--flow-space-xxl);
}

.flow-l :is(h2 + *) {
  --flow-space-m: clamp(0.938rem, 0.71rem + 1.14vw, 1.563rem);
  margin-top: var(--flow-space-m);
}

.flow-l :is(.step-question) {
  --flow-space-xxl: clamp(1.25rem, 0.114rem + 5.68vw, 4.375rem);
  margin-top: var(--flow-space-xxl);
}

.flow-m>*:not(.not-flow)+* {
  --flow-space-m: clamp(0.938rem, 0.71rem + 1.14vw, 1.563rem);
  margin-top: var(--flow-space-m);
}

.flow>*:not(.not-flow)+*,
.flow>li:not(.exc)+li {
  --flow-space: clamp(0.625rem, 0.511rem + 0.57vw, 0.938rem);
  margin-top: var(--flow-space);
}

.flow-s>*:not(.not-flow)+*,
.flow-s>li:not(.exc)+li {
  --flow-space-ss: clamp(0.313rem, 0.244rem + 0.34vw, 0.5rem);
  margin-top: var(--flow-space-ss);
}

.flow iframe {
  aspect-ratio: 16/9;
  height: unset;
  width: 100%;
}

// .flow:not(:has(.ws-pw)) :is(pre, pre + *, figure, figure) {
//   --flow-space-l: clamp(1rem, 0.455rem + 2.73vw, 2.5rem);
//   margin-top: var(--flow-space-l);
// }
.flow:is(pre, pre + *, figure, figure) {
  --flow-space-l: clamp(1rem, 0.455rem + 2.73vw, 2.5rem);
  margin-top: var(--flow-space-l);
}

.flow :is(h2, h2 + *) {
  --flow-space-m: clamp(0.938rem, 0.71rem + 1.14vw, 1.563rem);
  margin-top: var(--flow-space-m);
}

.flow :is(h3, h4, h3 + *, h4 + *) {
  --flow-space-s: clamp(0.5rem, 0.455rem + 0.23vw, 0.625rem);
  margin-top: var(--flow-space-s);
}

.prose.flow :is(h2, h2 + *) {
  --flow-space-xl: clamp(1.25rem, 1.023rem + 1.14vw, 1.875rem);
  margin-top: var(--flow-space-xl);
}

.prose.flow :is(h3, h3 + *) {
  --flow-space-l: clamp(1rem, 0.455rem + 2.73vw, 2.5rem);
  margin-top: var(--flow-space-l);
}

.prose.flow :is(h4, h4 + *) {
  --flow-space-m: clamp(0.625rem, 0.398rem + 1.14vw, 1.25rem);
  margin-top: var(--flow-space-m);
}

//---------------------------

/* mixin */

//---------------------------
//SVG
@mixin svgIcon($value) {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url("../../images/front/svg/"+ $value + ".svg") center no-repeat;
  background-size: contain;
}
@mixin svgMaskIcon($value) {
  content: "";
  display: inline-block;
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-image: url("../../images/front/svg/"+ $value + ".svg");
}

//矢印アイコン
@mixin arrow($direction, $size, $color, $width:2px) {
  content: "";
  border: solid $color;
  border-width: 0 $width $width 0;
  display: inline-block;
  padding: $size;

  @if $direction==top {
    transform: rotate(-135deg);
  }

  @if $direction==bottom {
    transform: rotate(45deg);
  }

  @if $direction==left {
    transform: rotate(135deg);
  }

  @if $direction==right {
    transform: rotate(-45deg);
  }
}

//三点リーダー
@mixin ellipsis($rows) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $rows;
  text-overflow: ellipsis;
}

.lines-1 {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lines-2 {
  @include ellipsis(2);
}

.lines-3 {
  @include ellipsis(3);
}

.lines-4 {
  @include ellipsis(4);
}

.lines-6 {
  @include ellipsis(6);
}

//製品LOGO
@mixin product-img($width, $height) {
  width: $width;
  max-width: $width;
  height: $height;
  max-height: $height;
  object-fit: contain;
  flex-shrink: 0;
  background: var(--color-white);
}

img.product-img[src$=".jpg"],
img.product-img[src$=".png"] {
  mix-blend-mode: multiply;
}

//ユーザーのアバター
%c-avatar {
  display: contents;
  width: calc(var(--size) * 30px);
  height: calc(var(--size) * 30px);
  border-radius: 50%;
  overflow: hidden;
  border-radius: 50%;
}

@mixin c-avatar-size($size) {
  @extend %c-avatar;
  --size: #{$size};
}

.size-106 {
  @extend %c-avatar;
  --size: #{3.53};

  .anonymous-reviewer {
    @include product-img(106px, 106px);
  }
}

.size-60 {
  @extend %c-avatar;
  --size: #{2};

  .anonymous-reviewer {
    @include product-img(60px, 60px);
  }

  >img {
    width: calc(var(--size) * 30px);
    border-radius: 50%;
  }
}

.size-30 {
  @extend %c-avatar;
  --size: #{1};

  .anonymous-reviewer {
    @include product-img(30px, 30px);
  }
}

//--------------------------- //

.remove-button,
.close {
  content: "";
  display: inline-block;
  vertical-align: middle;
  @include svgIcon("icon-cross-circle");
  width: 48px;
  height: 48px;
}

//---------------------------

/* LINK */

//---------------------------
a {
  display: block;
  color: currentColor;
  cursor: pointer;
  padding: var(--gutter-ss);

  &:not([class]) {
    text-decoration-skip-ink: auto;
  }

  // &:visited {
  //   color: inherit;
  // }
  .prose & {
    display: inline;
    color: var(--color-blue);
    padding: 0;
    text-decoration: underline;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  &:hover {
    border-radius: var(--border-radius);
    background: var(--color-primary-bright);

    .prose & {
      background: transparent;
      color: var(--color-primary);
    }
  }
}

.prose .black-btn a {
  color: var(--color-white);
  text-decoration: none;
}

.link {
  display: block;
  color: var(--color-blue);
  padding: 0;

  &:hover {
    color: var(--color-primary);
    background: transparent;
  }

  &.link-inline {
    display: inline;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  &--line {
    text-decoration: underline;
    padding: 0;

    &:hover {
      color: var(--color-primary);
      background: transparent;
    }
  }

  &--r,
  &--c {
    --m-t-txt-unit: clamp(0.938rem, 0.824rem + 0.57vw, 1.25rem);
    margin-top: var(--m-t-txt-unit);
  }

  &--r {
    @extend .link;
    text-align: right;
    font-weight: bold;
  }

  &--c {
    @extend .link;
    text-align: center;
  }

  &--p {
    @extend .link;
    padding: var(--gutter-ss);
  }

  >a {
    padding: 0;
    display: inline;
    color: var(--color-blue);

    &:hover {
      color: var(--color-primary);
      background: transparent;
    }
  }
}

.disabled {
  color: var(--color-gray);
  pointer-events: none;
}

//---------------------------

/* BUTTONS */

//---------------------------

%btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size-w, auto);
  height: var(--btn-size-h, 44px);
  --m-t-btn-unit: clamp(1.25rem, 1.023rem + 1.14vw, 1.875rem);
  margin-top: var(--m-t-btn-unit);
  position: relative;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  max-inline-size: var(--size-content, max-content);
  margin-inline: auto;
  flex: none;
  line-height: 1.2;

  @media #{$sp} {
    font-size: 14px;
    max-width: 300px;
    max-inline-size: 300px !important;
  }

  >a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;

    &:disabled {
      opacity: 0.65;
      cursor: not-allowed;
      background: var(--color-gray);
    }

    &.pd-0 {
      padding: 0;
    }
  }

  &:not(:disabled):active {
    background: var(--color-primary);
  }
  &.thin {
    height: 40px;
  }
  &.non-shadow {
    box-shadow: none;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--unit, 30px);
  text-decoration: none;
  border-radius: 5px;

  @media #{$sp} {
    padding: 0 var(--unit, 15px);
  }
}

.btn-white {
  @extend %btn;
  color: var(--color-black);
  background: var(--color-white);
  border: 1px solid var(--color-gray-pale);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

}

.btn-gray {
  @extend %btn;
  color: var(--color-white);
  background: var(--color-gray);

}

.btn-black {
  @extend %btn;
  color: var(--color-white);
  background: var(--color-black);

}

.btn-white-blue {
  @extend %btn;
  color: var(--color-white);
  background: var(--color-white);
  border: 1px solid var(--color-blue);

  >a {
    color: var(--color-blue);
    text-decoration: none;
  }

  &.arrow-b:after {
    @include arrow(bottom, 3px, var(--color-blue));
    position: absolute;
    bottom: -17px;
    right: 50%;
  }

  &.arrow-t:after {
    @include arrow(top, 3px, var(--color-blue));
    position: absolute;
    top: -17px;
    right: 50%;
  }
}

.btn-blue {
  @extend %btn;
  color: var(--color-white);
  background: var(--color-blue);
}

.btn-light-blue {
  @extend %btn;
  color: var(--color-white);
  background: var(--color-light-blue);
}

.btn-gradient {
  @extend %btn;
  color: var(--color-white);
  background: var(--color-gradient-primary);
}

.btn-cmn {
  @extend %btn;
  border: 1px solid var(--color-gray-pale);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  .d-f & {
    margin: 0;
  }

  >.active {
    background: var(--color-primary-bright);
  }

  // TODO：これ使っていない気がするな。。確認して消す
  &:not(:empty) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    text-decoration: none;
  }
}
.btn-primary {
  @extend %btn;
  border: 1px solid var(--color-gray-pale);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  .d-f & {
    margin: 0;
  }

  >.active {
    color: var(--color-white);
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
  }

  a {
    &.no-decoration {
      color: inherit;
      text-decoration: none;
    }
    &:hover {
      border-radius: 0;
      &:not(.active) {
        color: var(--color-primary);
        background: var(--color-white);
      }
    }
    
    &.arrow-r::after {
      margin-left: 1rem;
      margin-bottom: 0.1rem;
    }
    &.arrow-t-c-primary::after {
      @include arrow(top, 3px, var(--color-primary));
    }
    &.arrow-c-primary::after {
      @include arrow(bottom, 3px, var(--color-primary));
    }
  }
}

.btn-group {
  border-radius: var(--border-radius);

  [class^="btn-"] {
    box-shadow: none;
    border: 1px solid var(--color-gray-pale);
    max-inline-size: none;
    margin-inline: auto;

    &:has(>.active) {
      border: 1px solid var(--color-primary);
    }
    &:first-child {
      border-right: none;
      border-radius: var(--border-radius) 0 0 var(--border-radius);
    }
    &:last-child {
      border-left: none;
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    a {
      &:hover {
        border-radius: 0;
        &:not(.active) {
          color: var(--color-primary);
          background: var(--color-white);
        }
      }
      
      &.arrow-r::after {
        margin-left: 1rem;
        margin-bottom: 0.1rem;
      }
      &.arrow-c-primary::after {
        @include arrow(bottom, 3px, var(--color-primary));
      }
    }
  }

  &.btn-group--borderless {
    [class^="btn-"] {
      &:not(:first-child):not(:last-child) {
        border-right: none;
        border-left: none;
        border-radius: 0;
      }
    }
  }
}

.simple {
  border: 1px solid var(--color-gray-pale);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  &.h-non-s:hover {
    box-shadow: none;
  }
}

$step: 1;

// 高さの設定
@for $i from 30 through 400 {
  $value: #{$i}px;

  .h-#{$i} {
    height: $value;
  }
}

$step: 1;

// 横の設定
@for $i from 60 through 600 {
  $value: #{$i}px;

  .max-w-#{$i} {
    width: 100%;
    max-width: $value;
    max-inline-size: $value !important;
  }
}

/* ボタン系のhover */

.btn,
.btn-white,
.btn-gray,
.btn-black,
.btn-white-blue,
.btn-blue,
.btn-light-blue,
.btn-gradient,
.btn-cmn {
  &:hover {
    color: var(--color-primary);
    background: var(--color-primary-bright);
  }

  >a {
    &:hover {
      color: var(--color-primary);
      background: var(--color-primary-bright);
    }
  }
}

//---------------------------

/* FONT */

//---------------------------
$font-value: 12;
$font-max: 42;
$step: 2;

@mixin font-style($font-size-value, $line-height-value) {
  font-size: #{$font-size-value}px;
  line-height: $line-height-value;
}

@while $font-value <=$font-max {
  $font-size-value: $font-value;
  $line-height-value: min(max($font-value * 0.1, 1.2), 1.4);

  $is-mobile-large: $font-value >=26;
  $font-size-value-mobile: if($is-mobile-large, 24px, $font-value - 2px);

  .font-#{$font-value} {
    @include font-style($font-size-value, $line-height-value);

    &--b {
      @include font-style($font-size-value, $line-height-value);
      font-weight: bold;
    }
  }

  @media #{$sp} {
    .font-#{$font-value} {
      font-size: $font-size-value-mobile;

      &--b {
        font-size: $font-size-value-mobile;

        font-weight: bold;
      }
    }
  }

  $font-value: $font-value+$step;
}

//---------------------------

/* margin padding */

//---------------------------
.pd {
  padding: var(--gutter-s);
}

.pd-l {
  //15-30
  --pd-l: clamp(0.938rem, 0.597rem + 1.7vw, 1.875rem);
  padding: var(--pd-l);
}

.pd-m {
  //10-20
  --pd-m: clamp(0.625rem, 0.398rem + 1.14vw, 1.25rem);
  padding: var(--pd-m);
}

.pd-x {
  //5-10
  --pd-x: clamp(0.313rem, 0.199rem + 0.57vw, 0.625rem);
  padding: var(--pd-x);
}

.pt-l {
  //15-30
  --pd-l: clamp(0.938rem, 0.597rem + 1.7vw, 1.875rem);
  padding-top: var(--pd-l);
}

.pb-l {
  //15-30
  --pd-l: clamp(0.938rem, 0.597rem + 1.7vw, 1.875rem);
  padding-bottom: var(--pd-l);
}

.mt-l {
  //15-30
  --mt-l: clamp(0.938rem, 0.597rem + 1.7vw, 1.875rem);
  margin-top: var(--mt-l);
}

.mt-m {
  //10-20
  --mt-m: clamp(0.625rem, 0.398rem + 1.14vw, 1.25rem);
  margin-top: var(--mt-m);
}

.mt-x {
  //5-10
  --mt-s: clamp(0.313rem, 0.199rem + 0.57vw, 0.625rem);
  margin-top: var(--mt-s);
}

.mb-l {
  //15-30
  --mt-l: clamp(0.938rem, 0.597rem + 1.7vw, 1.875rem);
  margin-bottom: var(--mt-l);
}

.mb-m {
  //10-20
  --mt-m: clamp(0.625rem, 0.398rem + 1.14vw, 1.25rem);
  margin-bottom: var(--mt-m);
}

.mb-x {
  //5-10
  --mt-s: clamp(0.313rem, 0.199rem + 0.57vw, 0.625rem);
  margin-bottom: var(--mt-s);
}

$spaces: ("a": ("top",
    "bottom",
    "left",
    "right"
  ),
  "x": ("left",
    "right"
  ),
  "y": ("top",
    "bottom"
  ),
  "t": ("top"
  ),
  "b": ("bottom"
  ),
  "l": ("left"
  ),
  "r": ("right"
  ));

$types: ("m", "margin"),
("p", "padding");
$step: 1;

@mixin apply-space($type, $space_types, $value) {
  @if $space_types==("top", "bottom", "left", "right") {
    #{$type}: $value;
  }

  @else {
    @each $type-suffix in $space_types {
      #{$type}-#{$type-suffix}: $value !important;
    }
  }
}

@each $prefix,
$type in $types {

  @each $suffix,
  $space_types in $spaces {
    @for $i from 0 through 100 {
      $value: #{$i * $step}px;
      $value-mobile: calc(#{$i * $step}px / 2);

      .#{$prefix}-#{$suffix}-#{$i} {
        @include apply-space($type, $space_types, $value);
      }

      @media #{$sp} {
        .#{$prefix}-#{$suffix}-#{$i} {
          @include apply-space($type, $space_types, $value-mobile);
        }
      }
    }
  }
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.pd-0 {
  padding: 0;
}

.pd-2-8 {
  padding: 2px 8px;
}

.m-x-0 {
  margin-inline: initial;
}

//左寄せ
.m-l {
  margin-right: auto;
  margin-left: 0;
}

//右寄せ
.m-r {
  margin-left: auto;
  margin-right: 0;
  text-align: right;
}

//センター
.m-auto {
  margin: 0 auto;
}

//スマホ用
@media #{$sp} {
  .sp-m-c {
    margin-right: auto;
    margin-left: auto;
  }
}

[id] {
  scroll-margin-top: 60px;
}

//---------------------------

/* COLOR */

//---------------------------

.orange {
  color: var(--color-orange);
  background: var(--color-orange-light);
}

.pink {
  color: var(--color-primary);
  background: var(--color-primary-bright);
}

.turquoise {
  color: var(--color-turquoise);
  background: var(--color-turquoise-light);
}

.green {
  color: var(--color-gray);
  background: var(--color-green-light);
}

.gray {
  color: var(--color-gray);
  background: var(--color-gray-bright);
}

.purple {
  color: var(--color-purple);
  background: var(--color-purple-light);
}

.blue {
  color: var(--color-blue);
  background: var(--color-blue-light);
}

$colors: ("transp": ("color": transparent,
    "background": transparent),
  "white": ("color": var(--color-white),
    "background": var(--color-white)),
  "white-off": ("color": var(--color-white-off),
    "background": var(--color-white-off)),
  "black": ("color": var(--color-black),
    "background": var(--color-black)),
  "primary": ("color": var(--color-primary),
    "background": var(--color-primary)),
  "primary-bright": ("color": var(--color-primary-bright),
    "background": var(--color-primary-bright)),
  "red": ("color": var(--color-red),
    "background": var(--color-red)),
  "cyan-blue": ("color": var(--color-cyan-blue),
    "background": var(--color-cyan-blue)),
  "blue": ("color": var(--color-blue),
    "background": var(--color-blue)),
  "blue-light": ("color": var(--color-blue-light),
    "background": var(--color-blue-light)),
  "blue-bright": ("color": var(--color-blue-bright),
    "background": var(--color-blue-bright)),
  "blue-pale": ("color": var(--color-blue-pale),
    "background": var(--color-blue-pale)),
  "gray": ("color": var(--color-gray),
    "background": var(--color-gray)),
  "gray-light": ("color": var(--color-gray-light),
    "background": var(--color-gray-light)),
  "gray-bright": ("color": var(--color-gray-bright),
    "background": var(--color-gray-bright)),
  "gray-pale": ("color": var(--color-gray-pale),
    "background": var(--color-gray-pale)),
  "gray-bluette": ("color": var(--color-gray-bluette),
    "background": var(--color-gray-bluette)),
  "yellow": ("color": var(--color-yellow),
    "background": var(--color-yellow)),
  "orange": ("color": var(--color-orange),
    "background": var(--color-orange)),
  "turquoise": ("color": var(--color-turquoise),
    "background": var(--color-turquoise)),
  "turquoise-light": ("color": var(--color-turquoise-light),
    "background": var(--color-turquoise-light)),
  "green": ("color": var(--color-green),
    "background": var(--color-green)),
  "green-light": ("color": var(--color-green-light),
    "background": var(--color-green-light)),
  "purple": ("color": var(--color-purple),
    "background": var(--color-purple)),
  "purple-light": ("color": var(--color-purple-light),
    "background": var(--color-purple-light)),
  "purple-pale": ("color": var(--color-purple-pale),
    "background": var(--color-purple-pale)),
  "gradient-primary": ("color": var(--color-gradient-primary),
    "background": var(--color-gradient-primary)),
  "gradient-second": ("color": var(--color-gradient-second),
    "background": var(--color-gradient-second)),
  "gradient-third": ("color": var(--color-gradient-third),
    "background": var(--color-gradient-third)),
  "gradient-fourth": ("color": var(--color-gradient-fourth),
    "background": var(--color-gradient-fourth)),
  "gradient-fifth": ("color": var(--color-gradient-fifth),
    "background": var(--color-gradient-fifth)),
  "gradient-sixth": ("color": var(--color-gradient-sixth),
    "background": var(--color-gradient-sixth)),
  "gradient-banner": ("color": var(--color-gradient-banner),
    "background": var(--color-gradient-banner)));

@each $color-name,
$color-properties in $colors {
  .c-#{$color-name} {
    color: map-get($color-properties, "color");
  }

  .bg-#{$color-name} {
    background: map-get($color-properties, "background");
  }
}

[class*="bg-gradient-second"] {
  color: var(--color-black);
}

//---------------------------

/* grid */

//---------------------------
//grid
%grid {
  display: grid;
  --gutter: 1rem; 
  grid-template-columns: repeat(var(--item-width), minmax(0, 1fr));
  gap: var(--gutter);

  @media #{$sp} {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.grid-list {
  &--2 {
    @extend %grid;
    --item-width: 2;
  }

  &--3 {
    @extend %grid;
    --item-width: 3;
  }

  &--4 {
    @extend %grid;
    --item-width: 4;

    @media #{$sp-tablet} {
      --item-width: 2;
    }
  }

  &--5 {
    @extend %grid;
    --item-width: 5;

    @media #{$tablet} {
      --item-width: 3;
    }
  }
}

%grid-cell {
  display: grid;
  grid-template-columns: repeat(var(--item-count), auto);

  @media #{$sp} {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.grid-cell {
  &--3 {
    @extend %grid-cell;
    --item-count: 3;
  }

  &--4 {
    @extend %grid-cell;
    --item-count: 4;
  }

  &--5 {
    @extend %grid-cell;
    --item-count: 5;
  }

  &--6 {
    @extend %grid-cell;
    --item-count: 6;
  }

  &--7 {
    @extend %grid-cell;
    --item-count: 7;
  }
}

.gap {
  gap: var(--gutter);
}

.gap-s {
  gap: var(--gutter-s);
}

.gap-ss {
  gap: var(--gutter-ss);
}

//dlリストの横並び
.dl-grid {
  display: grid;
  grid-template-columns: auto 1fr;

  dd {
    margin-left: 5px;

    span {
      &+span {
        margin-left: 10px;
      }
    }
  }
}

//---------------------------

/* flex */

//---------------------------

@mixin gentle-flex($alignment: center, $justify: center) {
  display: flex;
  align-items: $alignment;
  justify-content: $justify;
}

.d-f {
  display: flex;
}

.d-if {
  display: inline-flex;
}

.fd-c {
  flex-direction: column;
}

.fd-cr {
  flex-direction: column-reverse;
}

.fd-r {
  flex-direction: row;
}

.fw-w {
  flex-wrap: wrap;
}

.fw-nw {
  flex-wrap: nowrap;
}

.fw-wr {
  flex-wrap: wrap-reverse;
}

.jc-c {
  justify-content: center;
}

.jc-fs {
  justify-content: flex-start;
}

.jc-fe {
  justify-content: flex-end;
}

.jc-sb {
  justify-content: space-between;
}

.jc-sa {
  justify-content: space-around;
}

.jc-se {
  justify-content: space-evenly;
}

.ji-c {
  justify-items: center;
}

.ai-c {
  align-items: center;
}

.ai-fs {
  align-items: flex-start;
}

.ai-fe {
  align-items: flex-end;
}

.ai-s {
  align-items: stretch;
}

.ai-b {
  align-items: baseline;
}

.ai-e {
  align-items: end;
}

.as-c {
  align-self: center;
}

.as-fs {
  align-self: flex-start;
}

.as-fe {
  align-self: flex-end;
}

.as-s {
  align-self: stretch;
}

.as-b {
  align-self: baseline;
}

.as-e {
  align-self: end;
}

.as-c {
  align-self: center;
}

.ff-rw {
  flex-flow: row wrap;
}

.f-1 {
  flex: 1 1;
}

.f-1-auto {
  flex: 1 1 auto;
}

.f-2 {
  flex: 2;
}

.f-2-5 {
  flex: 2.5;
}

.f-3 {
  flex: 3;
}

.f-3-5 {
  flex: 3.5;
}

.f-4 {
  flex: 4;
}

.f-4-5 {
  flex: 4.5;
}

.f-5 {
  flex: 5;
}

.f-6 {
  flex: 6;
}

.f-7 {
  flex: 7;
}

.f-auto {
  flex: auto;
}

.f-none {
  flex: none;
}

.f-g-1 {
  flex-grow: 1;
}

.f-s-0 {
  flex-shrink: 0;
}

.fd-rr {
  flex-direction: row-reverse;
}

.d-f-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}

.fb-auto {
  flex-basis: auto;
}
//PC
@media #{$pc} {
  .pc-f-1{
    flex: 1;
  }
}

//タブレット＆スマホ
@media #{$sp-tablet} {
  .sp-tablet-d-b {
    display: block;
  }

  .sp-tablet-d-f {
    display: flex;
  }

  .sp-tablet-d-c {
    display: contents;
  }

  .sp-tablet-jc-fs {
    justify-content: flex-start;
  }

  .sp-tablet-jc-c {
    justify-content: center;
  }

  .sp-tablet-ai-c {
    align-items: center;
  }

  .sp-tablet-fd-c {
    flex-direction: column;
  }

  .sp-tablet-fd-cr {
    flex-direction: column-reverse;
  }

  .sp-tablet-fd-unset {
    flex-direction: unset;
  }
}

//スマホ
@media #{$sp} {
  .sp-d-b {
    display: block;
  }

  .sp-d-f {
    display: flex;
  }

  .sp-d-g {
    display: grid !important;
  }

  .sp-d-c {
    display: contents;
  }

  .sp-jc-fs {
    justify-content: flex-start;
  }

  .sp-jc-c {
    justify-content: center;
  }

  .sp-jc-fe {
    justify-content: flex-end;
  }

  .sp-ai-c {
    align-items: center;
  }

  .sp-ai-s {
    align-items: start;
  }

  .sp-ai-e {
    align-items: end;
  }

  .sp-fd-c {
    flex-direction: column;
  }

  .sp-fd-cr {
    flex-direction: column-reverse;
  }

  .sp-fd-unset {
    flex-direction: unset !important;
  }

  .sp-fb-auto {
    flex-basis: auto !important;
  }
  .sp-m-auto{
    margin: 0 auto;
  }
  .sp-f-1 {
    flex: 1 1;

}
}

//---------------------------

/* common */

//---------------------------
//table
.table-l-fixed {
  table-layout: fixed;
}

.z-1 {
  z-index: 1;
}

.hidden {
  display: none;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.min-w {
  min-width: 0;
}

.max-w {
  max-width: var(--main-content-width);
}

.w-max-c {
  width: max-content;
}

.w-fc {
  width: fit-content;
}

.d-b {
  display: block;
}

.d-c {
  display: contents;
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.d-i {
  display: inline;
}

.d-ib {
  display: inline-block;
}

.d-c {
  display: contents;
}

.bor {
  border: 1px solid var(--color-gray-bright);
}

.bor-gray {
  border: 1px solid var(--color-gray-pale);
}

.bor-t {
  border-top: 1px solid var(--color-gray-bright);
}

.bor-b {
  border-bottom: 1px solid var(--color-gray-bright);
}

.box-s {
  box-shadow: var(--box-shadow);
}

.bor-r {
  border-radius: var(--border-radius);
}

.bor-r30 {
  border-radius: 30px;
}

.bor-r50 {
  border-radius: 50px;
}

.font-w-n {
  font-weight: normal;
}

.over-h {
  overflow: hidden;
}

.over-v {
  overflow: visible;
}

.line-h-1 {
  line-height: 1;
}
.line-h-1-1 {
  line-height: 1.1;
}
.line-h-1-2 {
  line-height: 1.2;
}

.over-x-auto {
  overflow-x: auto;
  white-space: nowrap;
  overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overscroll-behavior-y: contain;
}

@media #{$sp} {
  .over-x-auto-sp {
    overflow-x: auto;
    white-space: nowrap;
    overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    overscroll-behavior-y: contain;

    &.w-90vw {
      width: 90vw;
    }

    &.w-100vw {
      width: 100vw;
    }
  }
}

.cursor-p {
  cursor: pointer;
}

//文字数縛り
.max-w-56ch {
  max-width: 56ch;
}

.txt-r {
  text-align: right;
}

.txt-l {
  text-align: left;
}

//中央寄せ
.txt-c {
  text-align: center;
}

.m-x-auto {
  margin: 0 auto;
}

.ws-pw p {
  white-space: pre-wrap;

  +p {
    margin-top: 20px;

    @media #{$sp} {
      margin-top: 10px;
    }
  }
}

.w-max {
  width: auto;
  max-inline-size: none;
  margin-inline: auto;
}

.w-auto {
  width: auto;
  max-inline-size: max-content;
  margin-inline: auto;
}

.bar-auto {
  overflow-y: auto;
}

.overflow-x {
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 24px;

  @media #{$sp} {
    scroll-padding: 0 12px;
  }
}

//スマホ用
@media #{$sp} {
  .sp-w-100 {
    width: 100%;
  }
  .sp-w-mc{
    width: max-content !important;
  }

  .sp-w-max {
    max-inline-size: none;
  }

  .sp-max-w {
    max-width: 100%;
    max-inline-size: 100% !important;
  }
  .sp-w-auto {
    width: auto !important;
  }
  .sp-h-auto {
    height: auto;
  }
  .sp-fw-w {
    flex-wrap: wrap;
  }
}

//中央配置
@mixin align-left {
  max-inline-size: max-content;
  margin-inline: auto;
}

//エラーメッセージ
.err-bor {
  border: 2px solid var(--color-red);
}

.err {
  color: var(--color-red);
  font-weight: 700;
}

//存在感けす
.show-for-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

//画像サイズ
.logo-wrap-20 {
  @include product-img(20px, 20px);
}

.logo-wrap-24 {
  @include product-img(24px, 24px);
}

.logo-wrap-30 {
  @include product-img(30px, 30px);
}

.logo-wrap-40 {
  @include product-img(40px, 40px);
}

.logo-wrap-50 {
  @include product-img(50px, 50px);
}

.logo-wrap-60 {
  @include product-img(60px, 60px);
}

.logo-wrap-75 {
  @include product-img(75px, 75px);
}

.logo-wrap-76 {
  @include product-img(76px, 76px);
}

.logo-wrap-85 {
  @include product-img(85px, 85px);
}

.logo-wrap-96 {
  @include product-img(96px, 96px);
}

.logo-wrap-100 {
  @include product-img(100px, 100px);
}

.logo-wrap-106 {
  @include product-img(106px, 106px);
}

.logo-wrap-110 {
  @include product-img(110px, 110px);
}

.logo-wrap-120 {
  @include product-img(120px, 120px);
}

.logo-wrap-150 {
  @include product-img(150px, 150px);
}

.logo-wrap-200 {
  @include product-img(200px, 200px);
}

//透過
.not-bg {
  background: transparent !important;
}